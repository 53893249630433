import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import ModalBox from "../../ModalBox";
import {Box, Heading, Text, Image, Flex} from "@chakra-ui/react";
import TextBox from "../../FromsElements/TextBox";
import {FormItem} from "../../../interfaces/Interfaces";
import {setFormData} from "../../../pages/Consultation/slice";
import {setStep, setMobile} from "../slice";
import otpDoneImage from "../../../assets/images/otp-done.svg";

interface SuccessProps {
    state: any,
    onExitProcess?: any,
    onContinueProcess?: any,
    dataHandler?: any
}

const Success = (props: SuccessProps) => {
    return (
        <Box>
            <Flex justifyContent="center">
                <Image src={otpDoneImage}></Image>
            </Flex>
            <Heading size="md" textAlign="center">Authorization was successfully!</Heading>
            <Text textAlign="center" p="15px" mx="15px">
                Mobile authorization complete! You can update your number anytime if needed.
            </Text>
        </Box>
    );
}

export default Success;