import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {Form, SectionObject} from "../../interfaces/Interfaces";

export interface mobileAuthenticatorState {
    step: number|null,
    mobile: string,
    otp_code: string,
    is_loading: boolean,
    default_resend_time: number,
    resend_time_left: number
}

export interface initAction {

}

export const steps = ['input_mobile', 'input_otp', 'final'];

const initialState: mobileAuthenticatorState = {
    step: null,
    mobile: '',
    otp_code: '',
    is_loading: false,
    default_resend_time: 62,
    resend_time_left: 62
}

export const MobileAuthenticatorSlice = createSlice({
    name: 'mobileAuthenticator',
    initialState,
    reducers: {
        init : (state, action: PayloadAction<mobileAuthenticatorState>) => {

        },
        nextStep: (state) => {

        },
        prevStep: (state) => {

        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setMobile: (state, action) => {
            state.mobile = action.payload;
        },
        setOtpCode: (state, action) => {
            state.otp_code = action.payload;
        },
        decreaseResendTimeLeft: (state) => {
            state.resend_time_left = state.resend_time_left - 1;
        },
        resetResendTimeLeft: (state) => {
            state.resend_time_left = state.default_resend_time;
        },
    },
})

export const { nextStep, prevStep, init, setStep, setMobile, setOtpCode, decreaseResendTimeLeft, resetResendTimeLeft} = MobileAuthenticatorSlice.actions

export default MobileAuthenticatorSlice.reducer