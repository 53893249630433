import React, {useEffect} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Text, FormControl, FormLabel, Input, Flex, InputProps} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import "./style.css";
interface TimePickerBoxInterFace extends FormItemProps, InputProps {

}

const TimePickerBox = (props: TimePickerBoxInterFace) => {
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    return (
        <FormControl w={props.w ?? ''}>
            {item.title.length > 0 &&
                <FormLabel flexDirection="column">
                    {item.required &&
                        <Text as="span" color="red.500" fontSize="14px" me="5px">*</Text>
                    }
                    <Text as="span">{item.title}</Text>
                </FormLabel>
            }

            <Input value={state && state[item.descriptor] ? state[item.descriptor] : ''}
                {...item?.options?.props} key={item.descriptor} className={IsInvalidMessage ? 'full red-border' : 'full'}
                placeholder={item.placeholder || ""}
                onChange={(e) => {
                    if (inputItemHandler) {
                        inputItemHandler(e.target.value, item);
                    }
                }}
                min='00:00' max= '23:59'
                type="time"
            />
            {!item?.hide_validation_error && IsInvalidMessage}
        </FormControl>
    );
}

export default TimePickerBox;
