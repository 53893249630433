import React, {useEffect, useState} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Text, FormControl, FormLabel, Input, Flex, Textarea, HStack, Box} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {InfoOutlineIcon} from "@chakra-ui/icons";
const TextAreaBox = (props: FormItemProps) => {
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    let recommendationData = props.recommendationData;
    const [recommendedValue, setrecommendedValue] = useState<any>(null);
    useEffect(() => {
        if (item && item?.getRecommendedValue) {
            let func = new Function('recommendations,data', item.getRecommendedValue);
            setrecommendedValue(func(item.recommendations, recommendationData))
        }
    }, [item])

    const [characterCount, setCharacterCount] = useState(0);

    return (
        <FormControl position="relative">
            {item.title.length > 0 &&
                <FormLabel flexDirection="column">
                    {item.required &&
                        <Text as="span" color="red.500" fontSize="14px" me="5px">*</Text>
                    }
                    <Text as="span">{item.title}</Text>
                </FormLabel>
            }
            <Box w="full" position="relative">
                <Textarea placeholder={item.placeholder && item.placeholder.length > 0 ? item.placeholder : ""}
                    {...item?.options?.props} fontSize="14px" fontWeight="400"
                    maxLength={item?.options?.max_character ? item?.options?.max_character : -1}
                    value={state && state[item.descriptor] ? state[item.descriptor] : ''} key={item.descriptor}
                    onChange={(e) => {
                        if (item?.options?.max_character) {
                            setCharacterCount(e.target.value.length)
                        }
                        if (inputItemHandler) {
                            inputItemHandler(e.target.value, item);
                        }
                    }}
                    isInvalid={IsInvalidMessage ? IsInvalidMessage : false}
                ></Textarea>
                {item?.options?.max_character &&
                    <Text color="gray.500" fontSize="12px" position="absolute" right="15px" bottom="1px">{characterCount} / {item?.options?.max_character}</Text>
                }
            </Box>
            {item.recommendations && recommendedValue !== null &&
                <HStack my="10px">
                    <InfoOutlineIcon color="#757575"></InfoOutlineIcon>
                    <Text flex="1" fontSize="14px" fontWeight="400" color="#757575">Recommended: <Text color="#2E2E2E" as="span">{recommendedValue}</Text></Text>
                    <Text fontSize="14px" fontWeight="400" color="#0065FF" cursor="pointer"
                          onClick={() => {
                              inputItemHandler(recommendedValue, item);
                          }}
                    >Apply Recommended</Text>
                </HStack>
            }
            {!item?.hide_validation_error && IsInvalidMessage}
        </FormControl>
    );
}

export default TextAreaBox;
