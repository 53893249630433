import { gql } from "@apollo/client";

export const GET_PARAMS_FORM_STRUCTURE = gql`
    query getParamsForm($id: Int!){
        form_schemas(where: {
                id: {_eq: $id},
                center_id: {_eq: ${process.env.REACT_APP_CENTER_ID}}
        }) {
            id,
            version,
            template
        }
    }
`;
//

