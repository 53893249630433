import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import {checkboxTheme as Checkbox} from "./CheckBox"
import {buttonTheme as Button} from "./Button"

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  disableTransitionOnChange: true,
};

const themes = {
  textStyles: {
    p: {
      fontWeight: 500
    }
  },
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box'
      },
      body: {
        margin: 0,
        padding: 0,
        fontWeight: 500
      },
      a : {
        textDecoration: 'none !important'
      }
    }
  },
  fonts: {
    heading: 'Lato',
    body: 'Open Sans',
  },
  fontWeight: {
    p: 500,
    text: 500,
  },
  components: {
    Checkbox,
    Button
  },
  colors: {
    blue: {
      50: '#EBF8FF',
      100: '#BEE3F8',
      200: '#90CDF4',
      300: '#63B3ED',
      400: '#4299E1',
      500: '#3182CE',
      600: '#2B6CB0',
      700: '#2C5282',
      800: '#2A4365',
      900: '#1A365D',
    },
    gray: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
    green: {
      50: '#BAFFDE',
      100: '#81FFC2',
      200: '#2DEC9D',
      300: '#2BDA91',
      400: '#22CB85',
      500: '#21B97A',
      600: '#1B9E69',
      700: '#137C51',
      800: '#0D5B3B',
      900: '#034329',
    },
  }
};

export const theme = extendTheme({
  config,
  ...themes,
});
