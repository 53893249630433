import ModalBox from "../../../../../components/ModalBox";
import {FiFilePlus} from "react-icons/fi";
import {Box, Button, Heading, Text, Alert, AlertIcon, VStack, Circle, Flex, HStack} from "@chakra-ui/react";
import SelectBox from "../../../../../components/FromsElements/SelectBox";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    ADD_TREATMENT_USER,
    ADD_TREATMENT_USER_SESSION_NOTES,
    ADD_TREATMENT_USER_SESSIONS,
    GET_TREATMENTS, UPDATE_TREATMENT_USER_SESSION_NOTE, UPDATE_TREATMENT_USER_SESSION_PRESCRIPTION
} from "../../queries";
import useAuth from "../../../../../hooks/useAuth";
import {useParams} from "react-router-dom";
import useCustomToast from "../../../../../hooks/useCustomToast";
import TextBox from "../../../../../components/FromsElements/TextBox";
import DatePickerBox from "../../../../../components/FromsElements/DatePickerBox";
import TextAreaBox from "../../../../../components/FromsElements/TextAreaBox";
import {FormItem} from "../../../../../interfaces/Interfaces";
import Uploader from "../../../../../components/Uploader";
import axiosInstance from "../../../../../helpers/axiosInstance";
// import {useDropzone} from "react-dropzone";
interface AddPictureModalProps {
    isOpen: boolean,
    closeAction: any,
    addAction: any,
    tags: Array<string>,
    basePath: string
    defaultState: {

    }
}
const AddPictureModal = (props:AddPictureModalProps) => {
    const [errors, setErrors] = useState<Array<string>>([]);
    const [isLoading, setIsLoading] = useState(false);

    const toast = useCustomToast();
    const [stateData, setStateData] = useState<any>({
        tag: props.tags[0],
    });
    const [loading, setLoading] = useState(false);

    const getExtraFields = () => {

        let values:any = {};

        for (let tag of props.tags) {
            values[tag] = {
                title: tag?.replace(/\w+/g,function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();})
                , value: tag
            }
        }

        return (
            <SelectBox
                item={{
                    title: "Tag",
                    descriptor: "tag",
                    values: values
                }}
                inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({...stateData, tag: value})
                }}
                state={stateData}
            >
            </SelectBox>
        );
    }

    return (
        <ModalBox isOpen={props.isOpen} onClose={() => {props.closeAction()}}
            size="xl"
        >
            <Uploader
                isLoading={isLoading}
                errorMessages={errors}
                extraFields={getExtraFields}
                cancelAction={() => {
                    props.closeAction();
                }}
                uploadAction={async (files: any) => {
                    setErrors([]);
                    if (files.length) {
                        setIsLoading(true);
                        let formData = new FormData();
                        for (let i = 0; i < files.length; i++) {
                            formData.append("files[]", files[i]);
                        }

                        formData.append("basePath", props.basePath + stateData.tag + '/');

                        try {
                            let result = await axiosInstance().post('/storage/upload', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            });

                            if (result.data.status === 0) {
                                toast.success('your files has been uploaded.')

                                props.closeAction();
                                props.addAction();
                            } else {
                                toast.error(result.data.message)
                            }

                        } catch (err:any) {
                            let response = err.response;
                            if (response?.status === 422) {
                                let errs:Array<string> = [];
                                Object.keys(response.data?.data?.errors).map((index) => {
                                    let cerr = response.data.data.errors[index];
                                    Object.keys(cerr).map((cindex) => {
                                        errs.push(cerr[cindex]);
                                    })
                                });

                                setErrors(errs);
                            } else {
                                toast.error('There is an error on uploading files.Please contact support.')
                            }
                        }
                        setIsLoading(false);
                    } else {
                        toast.error('Please select at least one file.')
                    }


                }}
            ></Uploader>
        </ModalBox>
    );
}

export default AddPictureModal;