import React from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Box, FormControl, FormLabel, Input, Flex, useRadio, UseRadioProps} from "@chakra-ui/react";

interface RadioCardProps extends UseRadioProps, FormItemProps {

}

const RadioCard = (props: any) => {
    const { getInputProps, getRadioProps } = useRadio(props)
    const input = getInputProps()
    const checkbox = getRadioProps()
    let item = props.item;
    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _focus={{
                    boxShadow: 'none',
                }}
                px={5}
                py={2}
            >
                {props.children}
            </Box>
        </Box>
    );
}

export default RadioCard;
