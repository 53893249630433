import * as React from "react"
import {
    Box, Heading, Flex, Image, Text, Card, CardBody, VStack, Button
} from "@chakra-ui/react"
import {Link as ReactRouterLink, useNavigate, useParams} from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import imageDivider from "../../assets/images/patient_resources/title_divider.svg";

import formBg from "../../assets/images/patient_resources/form-bg.svg";
import TextBox from "../FromsElements/TextBox";
import {useState} from "react";
import {setMobile} from "../MobileAuthorizer/slice";
interface BookConsultationFormProps {
    data: any
};

export const BookConsultationForm = (props: BookConsultationFormProps) => {
    const data = props.data;
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        // mobile: ''
    });

    return (
        <Card w="full" p="0" mb="85px" borderRadius="24px" overflow="hidden">
            <CardBody p="0">
                <Flex>
                    <Image src={formBg} display={{base: 'none', lg: 'flex'}}></Image>
                    <VStack p="15px" flexGrow="1" justifyContent="center" alignItems="center">
                        <Box textAlign="start">
                            <Heading fontSize="36px" fontWeight="700" maxW="380px">
                                {data.title}
                            </Heading>
                            <Image src={imageDivider} mt="10px" mb="25px"></Image>
                        </Box>
                        <Flex w="full" maxW="full" flexWrap="wrap">
                            <Box w={{base: 'full', md: '50%'}} p="10px">
                                <TextBox w="full" state={formData} item={
                                        {
                                        "title" : "First Name",
                                        "placeholder" : "First name",
                                        "descriptor": "first_name",
                                        "values" : []
                                        }
                                    }
                                    inputItemHandler={(value:string,item:any) => {
                                        setFormData({...formData, first_name: value})
                                    }}
                                ></TextBox>
                            </Box>
                            <Box w={{base: 'full', md: '50%'}} p="10px">
                                <TextBox w="full" state={formData} item={
                                    {
                                    "title" : "Last Name",
                                    "placeholder" : "Last name",
                                    "descriptor": "last_name",
                                    "values" : []
                                    }
                                    }
                                    inputItemHandler={(value:string,item:any) => {
                                        setFormData({...formData, last_name: value})
                                    }}
                                ></TextBox>
                            </Box>
                            <Box w={{base: 'full', md: '50%'}} p="10px">
                                <TextBox w="full" state={formData} item={
                                    {
                                    "title" : "Email",
                                    "placeholder" : "Example@mail.com",
                                    "descriptor": "email",
                                    "values" : []
                                    }
                                    }
                                    inputItemHandler={(value:string,item:any) => {
                                        setFormData({...formData, email: value})
                                    }}
                                ></TextBox>
                            </Box>
                            <Box w={{base: 'full', md: '50%'}} p="10px">
                                <TextBox w="full" state={formData} item={
                                    {
                                    "title" : "Phone",
                                    "placeholder" : "+61 4XX XXX XXX",
                                    "descriptor": "mobile",
                                    "values" : []
                                    }
                                    }
                                    inputItemHandler={(value:string,item:any) => {
                                        // setFormData({...formData, mobile: value})
                                    }}
                                ></TextBox>
                            </Box>
                        </Flex>
                        <Box w="full" maxW="380px">
                            <Button w="full" bg="blue.900" color="white" borderRadius="40px"
                                onClick={() => {
                                    navigate('/consultation/request', { state: formData });
                                }}
                            >
                                Book Now
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
            </CardBody>
        </Card>
    );

}