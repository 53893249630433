import * as React from "react"
import {
    Box, Heading, Flex, Image, Text, Button, HStack, Container
} from "@chakra-ui/react"
import {Link as ReactRouterLink, useParams} from 'react-router-dom';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import logo from "../../../assets/images/logo.svg";
import {HamburgerIcon} from "@chakra-ui/icons";
import {useState} from "react";
interface PageHeaderProps {

};

export const PageHeader = (props: PageHeaderProps) => {

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Container maxW="container.xl" h="90px">
            <Flex h="full" w="" justifyContent="space-between" alignItems="center" borderBottom="1px solid"
                borderColor="gray.100">
                <Image width="60px" src={logo}></Image>
                <Button display={{base: 'flex', md: 'none'}}
                    onClick={() => {
                        setMenuOpen(!menuOpen);
                    }}
                >
                    <HamburgerIcon></HamburgerIcon>
                </Button>
                <HStack spacing="35" position={{base: 'absolute', md: 'relative'}}
                    overflow="hidden"
                    py={{base: (menuOpen? '15px' : '0px'), md: '0'}}
                    bg={{base: 'white', md: 'transparent'}}
                    flexDirection={{base: 'column', md: 'row'}} zIndex="100000"
                    top={{base: '90px', md: 'auto'}}
                    right={{base: '0', md: 'auto'}}
                    left={{base: '0', md: 'auto'}}
                    h={{base: (menuOpen? 'auto' : '0px'), md: 'auto'}}
                >
                    <ChakraLink fontWeight="500" fontFamily="16px" as={ReactRouterLink} to='/patients/resources'>
                        Patient Resources
                    </ChakraLink>
                    <ChakraLink  fontWeight="500" fontFamily="16px" as={ReactRouterLink} to='/consultation/request'>
                        Request Consultation
                    </ChakraLink>
                </HStack>
                <ChakraLink bg="#181D40" display={{base: 'none', md: 'flex'}} borderRadius="100px" fontWeight="500" fontSize="15px" py="10px" px="20px" color="white" as={ReactRouterLink} to='/consultation/request'>
                    Booking Online
                </ChakraLink>
            </Flex>
        </Container>
    );

}