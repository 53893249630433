import ModalBox from "../../../../../components/ModalBox";
import {FiFilePlus} from "react-icons/fi";
import {Box, Button, Heading, Text, Alert, AlertIcon, VStack, Circle, Flex, HStack} from "@chakra-ui/react";
import SelectBox from "../../../../../components/FromsElements/SelectBox";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    ADD_TREATMENT_USER,
    ADD_TREATMENT_USER_SESSION_PRESCRIPTIONS,
    ADD_TREATMENT_USER_SESSIONS,
    GET_TREATMENTS, UPDATE_TREATMENT_USER_SESSION_PRESCRIPTION
} from "../../queries";
import useAuth from "../../../../../hooks/useAuth";
import {useParams} from "react-router-dom";
import useCustomToast from "../../../../../hooks/useCustomToast";
import TextBox from "../../../../../components/FromsElements/TextBox";
import DatePickerBox from "../../../../../components/FromsElements/DatePickerBox";
import TextAreaBox from "../../../../../components/FromsElements/TextAreaBox";
import {FormItem} from "../../../../../interfaces/Interfaces";
interface AddPrescriptionModalProps {
    isOpen: boolean,
    closeAction: any,
    addAction: any,
    prescriptions: Array<any>,
    defaultState: {
        id: number|null,
        text: String|null,
        prescription_id: number|null,
    }
}
const AddPrescriptionModal = (props:AddPrescriptionModalProps) => {
    const {user_id} = useAuth();
    let params = useParams();
    const [addSessionModalIsOpen, setAddPrescriptionModalIsOpen] = useState(false);
    const [addTreatmentUserSessionPrescriptions] = useMutation(ADD_TREATMENT_USER_SESSION_PRESCRIPTIONS)
    const [updateTreatmentUserSessionPrescriptionByPk] = useMutation(UPDATE_TREATMENT_USER_SESSION_PRESCRIPTION)
    const toast = useCustomToast();
    const [stateData, setStateData] = useState<any>(props.defaultState);
    const [prescriptionList, setPrescriptionList] = useState<any>({})

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let temp:any = {};
        if (props.prescriptions.length) {
            for (let prescription in props.prescriptions) {
                temp[props.prescriptions[prescription].id] = {
                    title: props.prescriptions[prescription].title,
                    value: props.prescriptions[prescription].id,
                    text: props.prescriptions[prescription].body
                };
            }

            temp[-1] = {
                title: "Custom",
                value: -1,
                text: ""
            };
        }

        setPrescriptionList(temp);
    }, [props.prescriptions]);



    const addPrescriptionModalCancelButton = () => {

        return (
            <Button variant="btnNoraml" border="1px solid" borderColor="gray.400" w="full"
                borderRadius="100px" h="48px" mb="30px" ms="45px"
                onClick={() => {
                    setStateData({text: '', prescription_id: -1});

                    props.closeAction()
                }}
            >Cancel</Button>
        );
    }

    const addPrescriptionModalActionButton = () => {

        return (
            <Button variant="btnMain" border="1px solid" borderColor="gray.400" w="full"
                borderRadius="100px" h="48px" mb="30px" me="45px" isDisabled={loading || stateData?.text?.length === 0}
                onClick={async () => {
                    setLoading(true);
                    let hasError = false;

                    if (stateData.id) {
                        let result = await updateTreatmentUserSessionPrescriptionByPk({
                            variables: {
                                id: stateData.id,
                                object:                                     {
                                    treatment_prescription_id: stateData.prescription_id > 0 ? stateData.prescription_id : null,
                                    text: stateData.text,
                                }
                            }
                        });
                        hasError = !result?.data?.update_treatment_user_session_prescription_by_pk?.id;
                    } else {
                        let result = await addTreatmentUserSessionPrescriptions({
                            variables: {
                                objects: [
                                    {
                                        patient_id: params.pid,
                                        treatment_prescription_id: stateData.prescription_id > 0 ? stateData.prescription_id : null,
                                        text: stateData.text,
                                        treatment_user_id: params.tid,
                                        treatment_user_session_id: params.sid,
                                        user_id: user_id
                                    }
                                ]
                            }
                        });
                        hasError = !result?.data?.insert_treatment_user_session_prescription?.affected_rows;
                    }
                    setLoading(false);
                    if (!hasError) {
                        toast.success('Prescription has been successfully saved.');
                        setStateData({text: '', id: null, prescription_id: null});
                        props.addAction();
                    }
                }}
            >Save</Button>
        );
    }

    return (
        <ModalBox isOpen={props.isOpen} onClose={() => {setStateData({text: '', id: null, prescription_id: null}); props.closeAction()}}
            size="xl" icon={FiFilePlus} iconsize="26px"
            cancelButton={addPrescriptionModalCancelButton()}
            actionButton={addPrescriptionModalActionButton()}
        >
            <Box>
                <Heading size="md" textAlign="center">Add new Prescription</Heading>

                <SelectBox state={stateData} item=
                    {
                        {
                            title: "Prescription",
                            descriptor: "prescription_id",
                            values: prescriptionList
                        }
                    }
                    inputItemHandler={(value:string, item:FormItem) => {
                        setStateData({...stateData, prescription_id: value, text: prescriptionList[value].text});
                    }}
                ></SelectBox>
                <Box my="25px"></Box>
                <TextAreaBox state={stateData}
                    item={{
                        descriptor: 'text',
                        title: "Prescription",
                        values: {},
                        options: {
                            props: {
                                h: "200px"
                            }
                        }
                    }}
                    inputItemHandler={(value:string, item:FormItem) => {
                        setStateData({...stateData, text: value, prescription_id: -1});
                    }}
                ></TextAreaBox>
            </Box>
        </ModalBox>
    );
}

export default AddPrescriptionModal;