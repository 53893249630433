import * as React from "react"
import {
    Box, Heading, Flex, Image, Text, Grid, GridItem
} from "@chakra-ui/react"
import {Link as ReactRouterLink, useParams} from 'react-router-dom'
import { Link as ChakraLink, LinkProps, Spinner } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import {PageHeader} from "../PageHeader";
import {PageFooter} from "../PageFooter";

interface MainTemplateProps{

};

export const MainTemplate = (props: any) => {

    const is_loading = props.is_loading ?? false;

    return (
        <Grid minH="100vh"
              templateAreas={`"header"
                  "main"
                  "footer"`}
              gridTemplateRows={'90px 1 1'}
              gridTemplateColumns={'1fr'}
              h='full'
              gap='1'
        >
            <GridItem area={'header'}>
                <PageHeader></PageHeader>
            </GridItem>
            <GridItem area={'main'} position="relative">
                { is_loading &&
                    <Flex position="absolute" top="0" left="0" bottom="0" right="0" w="full" h="full"
                        justifyContent="center" alignItems="center">
                        <Spinner />
                    </Flex>
                }
                {props.children}
            </GridItem>
            <GridItem area={'footer'}>
                <PageFooter></PageFooter>
            </GridItem>
        </Grid>
    );

}