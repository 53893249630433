import * as React from "react"
import {
    Box, Heading, Flex, Image, Text, Container, Card, HStack, VStack, Button
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import doctor from "../../../assets/images/doctor.png"
import PatientSearchBox from "../../../components/FromsElements/PatientSearchBox";
import emptyImage from "../../../assets/images/appointment_empty.svg"
import {GET_USER_DATA} from "./queries";
import {useLazyQuery, useMutation} from "@apollo/client";
import useAuth from "../../../hooks/useAuth";
import {useEffect} from "react";

export const Home = () => {

    const {user_id} = useAuth();
    const [getUserData, {error, loading, data, called}] = useLazyQuery(GET_USER_DATA, {
        variables: {
            id: user_id
        }
    });

    useEffect(() => {
        if (user_id > 0) {
            getUserData();
        }
    }, [user_id]);

    return (
        <Container maxW="container.md">
            <HStack alignItems="flex-end" bg="white" w="full" h="176px" my="56px" boxShadow="sm" borderRadius="16px"
                    border="0">
                <VStack flexGrow="1" h="full" justifyContent="center" alignItems="start" ps="40px">
                    <Heading fontSize={{base: "16px", sm: "20px", md: "36px"}} fontWeight="700">
                        Welcome, <Text as="span" color="blue" fontWeight="bolder">{data?.users_by_pk?.name}</Text>
                    </Heading>
                    <Text textAlign="start" fontWeight="500" fontSize={{base: "12px", sm: "14px", md: "20px"}}
                          color="gray">Have a nice day at work</Text>
                </VStack>
                <Image w="219px" src={doctor}/>
            </HStack>

            <Box>
                <PatientSearchBox></PatientSearchBox>
            </Box>
            <HStack my="30px" w="full" justifyContent="center">
                <Button as={ReactRouterLink} to="/dashboard/patient/list/" variant="outline">See booking requests</Button>
            </HStack>
            <Box w="full" mt="50px">
                <Heading fontSize="24px" mb="25px">Upcoming Appointments</Heading>

                <VStack w="full" my="30px">
                    <Image src={emptyImage}></Image>
                    <Text fontSize="14px" fontWeight="400" color="gray">There are currently no data</Text>
                </VStack>


            </Box>
        </Container>
    )
}

