import { decodeToken } from 'react-jwt';
const useAuth = () => {

    let accessToken = localStorage.getItem('access_token');

    const tokenPayload = accessToken && decodeToken(accessToken as string);
    const claims = (tokenPayload as any)?.custom_claims;
    const userId = claims?.['x-hasura-user-id'];
    const roles = claims?.['x-hasura-allowed-roles'] || [];

    return {user_id: userId, roles: roles};
}

export default useAuth;