import {gql} from "@apollo/client";
import {Form_Data_Set_Input} from "../../../gql/graphql";

export const GET_PATIENTS_WITH_CONSULTATION_FORM_COUNT = gql`
    query getPatientsCount($keyword: String!) {
        form_data_aggregate(where: {
            center_id: {
                _eq: ${process.env.REACT_APP_CENTER_ID}
            },
            form: {
                descriptor: {
                    _eq: "CONSULTATION_FORM"
                }
            },
            patient: {
                _and: {
                    _or: [
                        {first_name: {_ilike: $keyword}},
                        {last_name: {_ilike: $keyword}},
                        {customer_code: {_ilike: $keyword}},
                        {mobile: {_ilike: $keyword}},
                        {email: {_ilike: $keyword}}
                    ]
                }
            }
        })
        {
            aggregate {
                count
            }
        }
    }
`;

export const GET_PATIENTS_WITH_CONSULTATION_FORM = gql`
    query getPatientsList($keyword: String! $limit: Int! $offset: Int!) {
        form_data(
            limit: $limit,
            offset: $offset,
            order_by: {
                created_at: desc
            },
            where: {
                center_id: {
                    _eq: ${process.env.REACT_APP_CENTER_ID}
                },
                form: {
                    descriptor: {
                        _eq: "CONSULTATION_FORM"
                    }
                },
                patient: {
                    _and: {
                        _or: [
                            {first_name: {_ilike: $keyword}},
                            {last_name: {_ilike: $keyword}},
                            {customer_code: {_ilike: $keyword}},
                            {mobile: {_ilike: $keyword}},
                            {email: {_ilike: $keyword}}
                        ]
                    }
                }
            })
            {
                id
                created_at
                patient {
                    address
                    birthday
                    contact_method
                    customer_code
                    email
                    email_verified_at
                    emergency_contact
                    first_name
                    id
                    last_name
                    mobile
                    mobile_verified_at
                    occupation
                    skin_type
                }
            }
    }
`;
