import {gql} from "@apollo/client";
import {Form_Data_Set_Input} from "../../../gql/graphql";

export const GET_PATIENT_DATA = gql`
    query getPatientData($id: Int!) {
        patients_by_pk(id: $id) {
            occupation
            mobile
            last_name
            first_name
            id
            emergency_contact
            email
            customer_code
            contact_method
            center_id
            birthday
            address
            skin_type
            patient_forms_data {
                id
                data
                created_at
                treatment_id
                user_id
                version
                form {
                    version
                    descriptor
                    center_id
                }
                center_id
            }
            treatments {
                created_at
                data
                description
                id
                session_number
                title
                treatment{
                    data
                    description
                    id
                    image
                    title
                    descriptor
                    form {
                        version
                        descriptor
                        center_id
                        template
                    }
                    prescriptions {
                        id
                        treatment_id
                        description
                        title
                        body
                    }
                }
                user_id
                status
                sessions(order_by: {id: asc}) {
                    created_at
                    date
                    end_time
                    duration
                    form_id
                    id
                    images {
                        created_at
                        extension
                        id
                        name
                        path
                        patient_id
                        size
                        treatment_user_id
                        treatment_user_session_id
                        user_id
                    }
                    notes(order_by: {id: asc}) {
                        created_at
                        id
                        patient_id
                        text
                        treatment_user_id
                        treatment_user_session_id
                        user_id
                    }
                    start_time
                    status
                    treatment_user_id
                    prescriptions(order_by: {id: asc}) {
                        created_at
                        id
                        patient_id
                        text
                        treatment_user_id
                        treatment_prescription_id
                        treatment_user_session_id
                        user_id
                    },
                    form {
                        id
                        data
                        created_at
                        treatment_id
                        user_id
                        version
                        form {
                            version
                            descriptor
                            center_id
                            template
                        }
                        center_id
                    }
                }
            }
        }
    }
`;

export const GET_TREATMENT_USER_DATA = gql`
    query getTreatmentUserData($id: Int!) {
        treatment_users_by_pk(id: $id) {
            data
            description
            id
            patient_id
            session_number
            status
            title
            user_id
            treatment_id
            treatment {
                id
                title
                descriptor
                form_id
                prescriptions {
                    id
                    treatment_id
                    description
                    title
                    body
                },
            }
            patient {
                id
                first_name
                last_name
                skin_type
            }
            sessions(order_by: {id: asc}) {
                created_at
                date
                end_time
                duration
                form_id
                id
                images {
                    created_at
                    extension
                    id
                    name
                    path
                    patient_id
                    size
                    treatment_user_id
                    treatment_user_session_id
                    user_id
                }
                notes(order_by: {id: asc}) {
                    created_at
                    id
                    patient_id
                    text
                    treatment_user_id
                    treatment_user_session_id
                    user_id
                }
                start_time
                status
                treatment_user_id
                prescriptions(order_by: {id: asc}) {
                    created_at
                    id
                    patient_id
                    text
                    treatment_user_id
                    treatment_prescription_id
                    treatment_user_session_id
                    user_id
                },
                form {
                    id
                    data
                    created_at
                    treatment_id
                    user_id
                    version
                    form {
                        version
                        descriptor
                        center_id
                        template
                    }
                    center_id
                }
            }
        }
    }
`;

export const GET_PATIENT_FORM_DATA = gql`
    query getPatientFormData($id: Int!) {
        form_data_by_pk(id: $id) {
            data
            form {
                version
                template
                id
                descriptor
            }
            patient {
                occupation
                mobile
                last_name
                first_name
                id
                emergency_contact
                email
                customer_code
                contact_method
                center_id
                birthday
                address
                skin_type
            }
            id
            patient_id
            treatment_id
            user_id
            version
        }
    }
`;

export const GET_TREATMENTS = gql`
    query getTreatments {
        treatments(where: {center_id: {_eq: ${process.env.REACT_APP_CENTER_ID}}}) {
            data
            description
            id
            image
            title
            descriptor
        }
    }
`;

export const ADD_TREATMENT_USER = gql`
    mutation addTreatmentUser($data: jsonb! $description: String! $patientId: Int! $sessionNumber: Int! $title: String! $treatmentId: Int! $userId: Int!) {
        insert_treatment_users_one(object: {data: $data, description: $description, patient_id: $patientId, session_number: $sessionNumber, title: $title, treatment_id: $treatmentId, user_id: $userId}) {
            data
            created_at
            description
            id
            patient_id
            session_number
            title
            treatment_id
            user_id
        }
    }
`;

export const DELETE_TREATMENT_USER = gql`
    mutation deleteTreatmentUser($id: Int!) {
        delete_treatment_users_by_pk(id: $id) {
            id
        }
    }
`;

export const CHANGE_TREATMENT_USER_STATUS = gql`
    mutation updateTreatmentUserStatus($id: Int! $status: String!) {
        update_treatment_users_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
            id
            status
        }
    }
`;

export const ADD_TREATMENT_USER_SESSIONS = gql`
    mutation addTreatmentUserSessions($objects: [treatment_user_sessions_insert_input]!) {
        insert_treatment_user_sessions(objects: $objects) {
            affected_rows,
            returning {
                id
            }
        }
    }
`;

export const UPDATE_TREATMENT_USER_SESSION_BY_PK = gql`
    mutation updateTreatmentUserSessionByPk($id: Int! $object: treatment_user_sessions_set_input!) {
        update_treatment_user_sessions_by_pk(pk_columns: {id: $id}, _set: $object){
            id
        }

    }
`;

export const ADD_TREATMENT_USER_SESSION_NOTES = gql`
    mutation addTreatmentUserSessionNotes($objects: [treatment_user_session_notes_insert_input]!) {
        insert_treatment_user_session_notes(objects: $objects) {
            affected_rows
        }
    }
`;

export const UPDATE_TREATMENT_USER_SESSION_NOTE = gql`
    mutation updateTreatmentUserSessionNoteByPk($id: Int! $object: treatment_user_session_notes_set_input!) {
        update_treatment_user_session_notes_by_pk(pk_columns: {id: $id}, _set: $object) {
            id
        }
    }
`;

export const ADD_TREATMENT_USER_SESSION_PRESCRIPTIONS = gql`
    mutation addTreatmentUserSessionPrescriptions($objects: [treatment_user_session_prescription_insert_input]!) {
        insert_treatment_user_session_prescription(objects: $objects) {
            affected_rows
        }
    }
`;

export const UPDATE_TREATMENT_USER_SESSION_PRESCRIPTION = gql`
    mutation updateTreatmentUserSessionPrescriptionByPk($id: Int! $object: treatment_user_session_prescription_set_input!) {
        update_treatment_user_session_prescription_by_pk(pk_columns: {id: $id}, _set: $object) {
            id
        }
    }
`;

export const GET_FORM_TEMPLATE = gql`
    query getFormTemplate($descriptor: String!){
        form_schemas(where: {
            descriptor: {_eq: $descriptor},
            center_id: {_eq: ${process.env.REACT_APP_CENTER_ID}}
        }) {
            id,
            version,
            template
        }
    }
`;

export const ADD_FORM_DATA = gql`
    mutation InsertFormData($data: jsonb! $patient_id: Int! $form_id: Int! $user_id: Int! $treatment_id: Int!) {
        insert_form_data_one(object: {center_id:  ${process.env.REACT_APP_CENTER_ID}, data: $data, form_id: $form_id, patient_id: $patient_id, version: 1, user_id: $user_id, treatment_id: $treatment_id}) {
            id
        }
    }
`;

export const UPDATE_FORM_DATA_BY_PK = gql`
    mutation updateFormDataByPk($id: Int! $object: form_data_set_input!) {
        update_form_data_by_pk(pk_columns: {id: $id}, _set: $object){
            id
        }

    }
`;

export const UPDATE_PATIENT_DATA = gql`
    mutation UpdatePatient($id: Int! $object: patients_set_input!) {
        update_patients_by_pk(pk_columns: {id: $id}, _set: $object) {
            id
        }
    }
`;

export const GET_SKIN_TYPE_META_DATA = gql`
    query getSkinTypeMetaData{
        meta_data(where: {center_id: {_eq: ${process.env.REACT_APP_CENTER_ID}}, descriptor: {_eq: "SKIN_TYPES"}}) {
            data
            descriptor
            id
        }
    }
`;

export const DELETE_TREATMENT_USER_SESSION_PRESCRIPTION = gql`
    mutation deleteTreatmentUserSessionPrescription($id: Int!) {
        delete_treatment_user_session_prescription_by_pk(id: $id) {
            id
        }
    }
`;

export const DELETE_TREATMENT_USER_SESSION_NOTE = gql`
    mutation deleteTreatmentUserSessionNote($id: Int!) {
        delete_treatment_user_session_notes_by_pk(id: $id) {
            id
        }
    }
`;