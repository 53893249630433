import React, {useEffect, useState} from 'react';
import {FormItemProps} from "../../../../interfaces/Interfaces";
import {Box, Flex, Text} from "@chakra-ui/react";
import CheckBox from "../../CheckBox";
const Form = (props: any) => {

    const item = props.item;
    const state = props.state;

    return (
        <Box>
            { (item.type === 'checkbox' ||  item.type === 'action_checkbox') &&
                <Box py="10px" borderBottom="1px solid" borderColor="gray.100">
                    <CheckBox state={state} item={item} isDisabled />
                </Box>
            }

            { item.type === 'yes_no_button' &&
                <>
                    <Flex alignItems="center" borderBottom="1px solid" borderColor="gray.100" py="10px">
                        {state[item.descriptor].toLowerCase() === 'yes' &&
                            <Text borderRadius="15px" py="5px" px="10px" me="15px" color="green.500" bg="green.100">Yes</Text>
                        }
                        {(!state[item.descriptor] || state[item.descriptor].toLowerCase() === 'no' || state[item.descriptor].toLowerCase() !== 'yes') &&
                            <Text borderRadius="15px" py="5px" px="10px" me="15px" color="gray.500" bg="gray.100">No</Text>
                        }
                        <Text>{item.title.length > 0 ? item.title : item.text}</Text>
                    </Flex>
                </>
            }

            { (item.type === 'input' || item.type === 'textarea' || item.type === 'group' || item.type === 'component' || item.type === 'radioCardGroup') &&
                <Box  py="10px" borderBottom="1px solid" borderColor="gray.100">
                    <Text fontWeight="normal" color="gray.500">{item.title.length > 0 ? item.title : item.text}</Text>
                    <Text>{state[item.descriptor]}</Text>
                </Box>
            }

            { (!!item?.values) && (item?.values.constructor === Object) && Object.keys(item.values).map((itemKey) => {
                return (
                    <>
                        { (state[item.descriptor] === item.values[itemKey]?.value || state[item.descriptor] === itemKey) && (item.values[itemKey]?.forms) &&
                            <>
                                {item.values[itemKey].forms.map((form:any) => {
                                    return (
                                        <>
                                            {
                                                form.items.map((formItem:any) => {
                                                    return (
                                                        <Form state={state} item={formItem}></Form>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                })
                                }
                            </>
                        }

                        { (state[item.descriptor] === itemKey && item.values[itemKey]?.items) &&
                            <>
                                {item.values[itemKey].items.map((extraItem:any) => {
                                    return (
                                        <Form state={state} item={extraItem}></Form>
                                    )
                                })
                                }
                            </>
                        }
                    </>
                )
            })
            }
        </Box>
    )
}

export default Form;