import {Center, Heading, Text, VStack, Box, Link, Flex} from "@chakra-ui/react";
import {AddIcon, DeleteIcon} from "@chakra-ui/icons";
import * as React from "react";
import {FormContainerProps} from "../../../interfaces/Interfaces"
import FormElementsContainer from "../FormElementsContainer";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

export const FormContainer = (props: FormContainerProps) => {

    const dispatch = useDispatch();
    let form = props.form;
    let currentStep = props.currentStep;
    let stepsCount = props.stepsCount;
    let inputItemHandler = props.inputItemHandler;
    let extendFormHandler = props.extendFormHandler;
    let state = props.state;
    let validator = props.validator;

    const extendForm = (type:string, index?:number) => {

        if (extendFormHandler) {
            dispatch(extendFormHandler({type, index}));
        }
    }

    return (
        <Center flexDirection="column" w="full" maxW="450px">
            {form.title &&
                <Heading fontSize="xl">
                    {form.title} {currentStep && stepsCount && <>({currentStep} of {stepsCount})</>}
                </Heading>
            }
            {form.description &&
                <Text py="20px" textAlign={form.show_type && form.show_type === 'attached' ? 'left' : 'inherit'} w="full" fontSize="16px">{form.description}</Text>
            }

            {form.items.length > 0 &&
                <VStack spacing={4} align='stretch' w="full">
                    {form.extendedItems && form.extendedItems.length > 0 &&
                        <Flex justifyContent="space-between">
                            <Text py="3px" px="5px" border="1px solid" borderColor="blue.200" bgColor="blue.100" borderRadius="5px">
                                {form.added_form_title + ' 1'}
                            </Text>
                        </Flex>
                    }
                    <FormElementsContainer validator={validator} items={form.items} inputItemHandler={inputItemHandler} state={state}></FormElementsContainer>
                </VStack>
            }

            {form.extendedItems && form.extendedItems.length > 0 &&
                <>
                    {form.extendedItems.map((iForm:any, index:number) => {
                        return (
                        <VStack spacing={4} align='stretch' w="full" mt="35px" key={index}>
                            <Flex justifyContent="space-between">
                                <Text py="3px" px="5px" border="1px solid" borderColor="blue.200" bgColor="blue.100" borderRadius="5px">
                                    {form.added_form_title + ' ' + (index + 2)}
                                </Text>
                                <Link  onClick={() => {
                                    extendForm('remove', index)
                                }}><DeleteIcon boxSize={5} /></Link>
                            </Flex>
                            <FormElementsContainer validator={validator} items={iForm} inputItemHandler={inputItemHandler} state={state}></FormElementsContainer>
                        </VStack>
                        )
                    })}
                </>
            }

            {form.can_add_form &&
                <Flex justifyContent="space-between" w="full" mt="20px">
                    <Text>{form.add_form_description}</Text>
                    <Link onClick={() => {
                        extendForm('add')
                    }}>{form.add_form_link_title} <AddIcon boxSize={3} /></Link>
                </Flex>
            }

        </Center>
    );
}