import ModalBox from "../ModalBox";
import {Box, Button, Heading, HStack, Spinner, Text, VStack} from "@chakra-ui/react";
import * as React from "react";
import {useEffect} from "react";
import {WarningTwoIcon} from "@chakra-ui/icons";
interface AddTreatmentModalProps {
    isOpen: boolean,
    closeAction: any,
    acceptAction: any,
    children?: any,
    isLoading?: boolean
}
const Confirmation = (props:AddTreatmentModalProps) => {
    const noButton = () => {
        return (
            <Button variant="btnNoraml" border="1px solid" borderColor="gray.200" w="full"
                borderRadius="100px" h="48px"
                onClick={() => {
                    props.closeAction()
                }}
            >No</Button>
        );
    }

    const yesButton = () => {
        return (
            <Button variant="btnNormal" border="1px solid" borderColor="gray.100" w="full" isDisabled={props.isLoading}
                borderRadius="100px" h="48px"
                onClick={() => {
                    props.acceptAction()
                }}
            >
                {props.isLoading &&
                    <Spinner mx="5px"></Spinner>
                }
                Yes
            </Button>
        );
    }

    return (
        <ModalBox isOpen={props.isOpen} onClose={() => {props.closeAction()}}
            size="sm" icon={WarningTwoIcon} iconsize="24px"
            cancelButton={noButton()}
            actionButton={yesButton()}
        >
            <Box w="full">
                {props.children}
            </Box>

        </ModalBox>
    );
}

export default Confirmation;