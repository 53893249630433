import * as React from "react"
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Container,
    VStack,
    Image,
    Text,
    Box,
    Heading, Divider, HStack
} from "@chakra-ui/react";
import {ChevronRightIcon} from "@chakra-ui/icons";
import imageDivider from "../../../assets/images/patient_resources/title_divider.svg";
import {useEffect} from "react";
interface SourceLayoutProps {
    layoutType?: string,
    width?: string
    data: {[key: string]: any}
}
export const SourceLayout = (props:SourceLayoutProps) => {

    let width:string = props.width ?? "full";
    let data = props.data;

    return (
        <Container w={width} maxW={width} p="0" mt="25px" mb="55px">
                {(!data.layout || data.layout === 'image_first') &&
                    <HStack w="full" alignItems="top" flexDirection={{base: 'column', lg: 'row'}}>
                        <Box w={{base: 'full', lg: '50%'}} mb="30px">
                            <Image w="full" src={data.image} borderRightRadius="20px"></Image>
                        </Box>
                        <Box w={{base: 'full', lg: '50%'}} px="55px" >
                            <Heading fontSize="30px" color="#2e2e2e">{data.title}</Heading>
                            <Image src={imageDivider} mb="25px" mt="10px"></Image>
                            <Text lineHeight="22px" fontSize="16px" fontWeight="400" color="#5C5C5C" dangerouslySetInnerHTML={{ __html: data.description }}></Text>
                        </Box>
                    </HStack>
                }

                {data.layout && data.layout === 'text_first' &&
                    <HStack w="full" alignItems="top" flexDirection={{base: 'column-reverse', lg: 'row'}}>
                        <Box w={{base: 'full', lg: '50%'}} px="55px" >
                            <Heading fontSize="30px" color="#2e2e2e">{data.title}</Heading>
                            <Image src={imageDivider} mb="25px" mt="10px"></Image>
                            <Text lineHeight="22px" fontSize="16px" fontWeight="400" color="#5C5C5C" dangerouslySetInnerHTML={{ __html: data.description }}></Text>
                        </Box>
                        <Box w={{base: 'full', lg: '50%'}}>
                            <Image w="full" src={data.image} borderRightRadius="20px" mb="30px"></Image>
                        </Box>
                    </HStack>
                }

            {data.layout && data.layout === 'full_text' &&
                <Box w="full">
                    {data?.title && data.title.length > 0 &&
                        <>
                            <Heading fontSize="30px" color="#2e2e2e">{data.title}</Heading>
                            <Image src={imageDivider} mb="25px" mt="10px"></Image>
                        </>
                    }
                    <Text lineHeight="22px" fontSize="16px" fontWeight="400" color="#5C5C5C" dangerouslySetInnerHTML={{ __html: data.description }}></Text>
                </Box>
            }

        </Container>
    );
}