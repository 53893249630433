import React, {useEffect} from 'react';
import {FormElementsContainerProps, FormItem} from "../../../interfaces/Interfaces";
import {Box, FormControl, FormLabel, Input, Alert, AlertIcon, Text} from "@chakra-ui/react";
import TextBox from "../TextBox";
import FormGroup from "../FormGroup";
import CheckBox from "../CheckBox";
import ActionCheckBox from "../ActionCheckBox";
import ButtonBox from "../ButtonBox";
import CustomComponentContainer from "../CustomComponentContainer";
import RadioCard from "../RadioCard/index";
import RadioCardGroup from "../RadioCardGroup";
import YesNoButton from "../YesNoButton";
import TextAreaBox from "../TextAreaBox";
import OptionBox from "../OptionBox";
import ActionOptionBox from "../ActionOptionBox";
import SelectBox from "../SelectBox";
// import {  } from '@chakra-ui/icons'
const FormElementsContainer = (props: FormElementsContainerProps) => {

    let items:Array<FormItem> | null | undefined = props.items;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let divider = props.divider;
    let recommendationData = props.recommendationData;

    return (
        <>
            {items && items.map((item, index) => (
                <Box key={item.descriptor} flex="1">
                    {item.type === 'input' &&
                        <TextBox recommendationData={recommendationData} validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></TextBox>
                    }
                    {item.type === 'textarea' &&
                        <TextAreaBox validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></TextAreaBox>
                    }
                    {item.type === 'group' &&
                        <FormGroup validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></FormGroup>
                    }
                    {item.type === 'radioCardGroup' &&
                        <FormGroup validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></FormGroup>
                    }
                    {item.type === 'checkbox' &&
                        <CheckBox validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></CheckBox>
                    }
                    {item.type === 'action_checkbox' &&
                        <ActionCheckBox validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></ActionCheckBox>
                    }
                    {item.type === 'button' &&
                        <ButtonBox validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></ButtonBox>
                    }
                    {item.type === 'alert' &&
                        <Alert status={item.options?.type} borderRadius="10px">
                            <AlertIcon />
                            {item.text}
                        </Alert>
                    }
                    {item.type === 'text' &&
                        <Text dangerouslySetInnerHTML={{__html: item.text ?? ''}}></Text>
                    }
                    {item.type === 'component' &&
                        <CustomComponentContainer validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></CustomComponentContainer>
                    }
                    {item.type === 'radioCardGroup' &&
                        <RadioCardGroup validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></RadioCardGroup>
                    }
                    {item.type === 'yes_no_button' &&
                        <YesNoButton validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></YesNoButton>
                    }
                    {item.type === 'option_box' &&
                        <OptionBox validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></OptionBox>
                    }
                    {item.type === 'select' &&
                        <SelectBox validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></SelectBox>
                    }
                    {item.type === 'action_option_box' &&
                        <ActionOptionBox validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></ActionOptionBox>
                    }

                    {divider}
                </Box>
            ))}
        </>
    );
}

export default FormElementsContainer;
