import * as React from "react"
import {
    Box,
    Heading,
    Flex,
    Image,
    Text,
    Grid,
    GridItem,
    Spinner,
    HStack,
    Card,
    CardBody,
    Button,
    Link,
    NumberInput,
    NumberInputField
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import {PageHeader} from "../../../components/Template/PageHeader";
import logo from "../../../assets/images/logo.svg";
import drsImg from "../../../assets/images/doctors.jpg";
import {useEffect, useState} from "react";
import {FormItem} from "../../../interfaces/Interfaces";
import TextBox from "../../../components/FromsElements/TextBox";
import {ArrowBackIcon, EditIcon} from "@chakra-ui/icons";
import useAuth from "../../../hooks/useAuth";
import SimpleReactValidator from "simple-react-validator";
import axiosInstance from "../../../helpers/axiosInstance";

export const Login = () => {

    const {user_id} = useAuth();
    const otpInputs:Array<FormItem> = [
        {
            title: "",
            descriptor: "otp_1",
            values: {}
        },
        {
            title: "",
            descriptor: "otp_2",
            values: {}
        },
        {
            title: "",
            descriptor: "otp_3",
            values: {}
        },
        {
            title: "",
            descriptor: "otp_4",
            values: {}
        },
        {
            title: "",
            descriptor: "otp_5",
            values: {}
        },
    ];
    const [cleared, setCleared] = useState(false)
    const [data, setData] = useState({
        mobile: '',
        otp: '',
        tries: 0
    })
    const [step, setStep] = useState(0);
    const [resendTimeLeft, setResendTimeLeft] = useState(0);
    const [validator, setValidator] = useState<any>(new SimpleReactValidator({
        element: (message:string) => {return(<Text textAlign="start" pt="10px" fontSize="sm" color="red.500">{message}</Text>)}
    }))

    const [sendOtpError, setSendOtpError] = useState('');
    const [checkOtpError, setCheckOtpError] = useState('');
    const [apiLoading, setApiLoading] = useState(false);

    const sendOtp = () => {
        setSendOtpError('');
        setCheckOtpError('');
        setApiLoading(true);
        axiosInstance().post('/otp/send', {
            number: data.mobile
        }).then((response) => {
            if (response.data.status === 0) {
                setStep(1);
                if (resendTimeLeft === 0) {
                    setResendTimeLeft(120);
                }
            } else {
                setSendOtpError(response.data.errors.number)
            }
            setApiLoading(false);
        }).catch((response) => {
            setSendOtpError(response.response.data.data.errors.number);
            setApiLoading(false);
        });
    }

    const checkOtp = () => {
        setSendOtpError('');
        setCheckOtpError('');
        setApiLoading(true);
        axiosInstance().post('/otp/check', {
            number: data.mobile,
            code: data.otp,
            authenticate: true
        }).then((response) => {
            if (response.data.status === 0) {
                localStorage.setItem('access_token', response.data.data.token);
                window.location.href = "/dashboard/";
            } else {
                setCheckOtpError(response.data.errors.number)
            }
            setApiLoading(false);
        }).catch((response) => {
            setCheckOtpError(response.response.data.data.errors.code)
            setApiLoading(false);
        });
    }

    useEffect(() => {
        if (user_id) {
            window.location.href = "/dashboard/";
        }
    }, []);

    useEffect(() => {
        if (resendTimeLeft > 0) {
            const interval = setInterval(() => {
                setResendTimeLeft(resendTimeLeft - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [resendTimeLeft]);

    const getHumanReadableResendTimeLeft = () => {
        let result = {
            m: 0,
            s: 0
        };

        if (resendTimeLeft > 0) {
            result.m = Math.floor(resendTimeLeft / 60);
            result.s = resendTimeLeft - (result.m * 60);
        }

        return result.m.toString().padStart(2, "0") + ':' + result.s.toString().padStart(2, "0");
    }
    return (
        <Grid minH="100vh"
            templateAreas={`"header"
              "main"
              "footer"`}
            gridTemplateRows={'60px 1fr 80px'}
            gridTemplateColumns={'1fr'}
            h='full'
            gap='1'
            fontWeight='bold'
        >
            <GridItem area={'header'} borderBottom="1px solid" borderColor="gray.200" px="30px">
                <HStack alignItems="center" h="full">
                    <Image src={logo} width="55px"></Image>
                    <Text fontSize="20px" fontWeight="normal">Trinity Skin Clinic</Text>
                </HStack>
            </GridItem>
            <GridItem area={'main'} position="relative" display="flex" alignItems="center" justifyContent="center">
                <HStack w="full" maxW="container.xl">
                    <Box w={{base: "full", md: "45%"}} p="15px">
                        <Card borderRadius="24px">
                            <CardBody p="35px" textAlign="center" justifyContent="center">
                                <Image src={logo} w="144px" mb="27px" display="inline-block"></Image>
                                { step === 0 &&
                                    <>
                                        <Heading fontSize="36px" fontWeight="bolder" mb="27px">
                                            Trinity Skin Clinic Account Login
                                        </Heading>
                                        <Text fontSize="16px" fontWeight="normal" mb="30px">
                                            Please enter your phone number to get verification code.
                                        </Text>

                                        <TextBox state={data} key="mobile"
                                            inputItemHandler={(value:string, item:FormItem) => {
                                                setData({...data, mobile: value});
                                            }}
                                            validator={validator}
                                            item={
                                                {
                                                    title: "Mobile Number",
                                                    descriptor: "mobile",
                                                    values: [],
                                                    placeholder: "0XXX XXX XXX",
                                                    validation_rule: "required"
                                                }
                                            }
                                        ></TextBox>
                                        {sendOtpError.length > 0 &&
                                        <Text color="red.500" textAlign="start">{sendOtpError}</Text>
                                        }
                                        <Button borderRadius="25px" mt="15px" px="35px" py="25px"
                                            isDisabled={apiLoading}
                                            onClick={() => {
                                                setData({...data, tries: data.tries + 1});
                                                if (validator.allValid()) {
                                                    sendOtp();
                                                } else {
                                                    validator.showMessages()
                                                }
                                            }}
                                        >{apiLoading && <Spinner size='sm' me="10px"/>} Get code</Button>
                                    </>
                                }
                                { step === 1 &&
                                    <>
                                        <Heading fontSize="36px" fontWeight="bolder" mb="27px">
                                            <ArrowBackIcon fontSize="24px" me="25px"></ArrowBackIcon>
                                            Verification Code
                                        </Heading>
                                        <Text fontSize="16px" fontWeight="normal" mb="30px" maxW="300px" m="auto">
                                            To verify your number, please complete the following step.
                                        </Text>

                                        <Box>
                                            <HStack justifyContent="space-between">
                                                <Text>Enter the 5-digit code sent to</Text>
                                                <Flex ps="10px" justifyContent="center" alignItems="center" color="blue.600">
                                                    {data.mobile}
                                                    <Link ps="5px" onClick={() => {
                                                        setStep(0)
                                                        if (resendTimeLeft === 0) {
                                                            setResendTimeLeft(120)
                                                        }
                                                    }}>
                                                        <EditIcon></EditIcon>
                                                    </Link>
                                                </Flex>
                                            </HStack>
                                            <HStack w="full" justifyContent="center" mt="25px">
                                                { otpInputs.map((otpItem, index) => {
                                                    return (
                                                        <NumberInput max={9} min={0} p="5px" key={otpItem.descriptor}
                                                             onKeyDown={(e) => {
                                                                 if (e.key === 'End' || e.key === 'Home') {
                                                                     return e.preventDefault();
                                                                 }
                                                             }}

                                                             onKeyUp={(e) => {
                                                                 if (e.key === 'Backspace' || e.key === 'Delete') {
                                                                     if (cleared) {
                                                                         if (index - 1 > -1) {
                                                                             if (document.querySelectorAll('.otp-field')[index - 1] !== undefined) {
                                                                                 (document.querySelectorAll('.otp-field')[index - 1] as HTMLInputElement)?.focus();
                                                                             }
                                                                         }
                                                                         setCleared(false);
                                                                     } else {
                                                                         setCleared(true);
                                                                     }
                                                                 } else if (e.key !== 'Tab') {
                                                                     if ((document.querySelectorAll('.otp-field')[index + 1] as HTMLInputElement)?.value.length > 0 && index + 1 < otpInputs.length) {
                                                                         if (document.querySelectorAll('.otp-field')[index + 1] !== undefined) {
                                                                             (document.querySelectorAll('.otp-field')[index + 1] as HTMLInputElement)?.focus();
                                                                         }
                                                                     }
                                                                 }
                                                             }}

                                                             onChange={(value) => {
                                                                 let otpCode = '';
                                                                 document.querySelectorAll('.otp-field').forEach((element) => {
                                                                     otpCode = otpCode + '' + (element as HTMLInputElement)?.value;
                                                                 });

                                                                 setData({...data, otp: otpCode});

                                                                 if (value.length > 0 && index + 1 < otpInputs.length) {
                                                                     if (document.querySelectorAll('.otp-field')[index + 1] !== undefined) {
                                                                         (document.querySelectorAll('.otp-field')[index + 1] as HTMLInputElement)?.focus();
                                                                     }
                                                                 }
                                                             }}

                                                             onFocus={(e) => {
                                                                 (document.querySelectorAll('.otp-field')[index] as HTMLInputElement)?.select();
                                                             }}
                                                        >
                                                            <NumberInputField w="50px" h="50px" p="0" maxLength={1} textAlign="center" className="otp-field"

                                                            />
                                                        </NumberInput>
                                                    );
                                                }) }
                                            </HStack>
                                            {checkOtpError.length > 0 &&
                                                <Text color="red.500" textAlign="start">{checkOtpError}</Text>
                                            }
                                            <Box textAlign="center">

                                            </Box>
                                            <Box textAlign="center" mt="10px">
                                                { resendTimeLeft > 0 &&
                                                    <Text>
                                                        {getHumanReadableResendTimeLeft()} To resend code
                                                    </Text>
                                                }

                                                { resendTimeLeft <= 0 &&
                                                    <Link onClick={() => {
                                                        sendOtp();

                                                    }}>Tap To Resend Code</Link>
                                                }
                                            </Box>
                                        </Box>

                                        <Button borderRadius="25px" mt="15px" px="35px" py="25px"
                                            isDisabled={data.otp.length !== 5 || apiLoading}
                                            onClick={() => {
                                                setData({...data, tries: data.tries + 1});
                                                if (validator.allValid()) {
                                                    checkOtp();
                                                } else {
                                                    validator.showMessages()
                                                }
                                            }}
                                        >
                                            {apiLoading && <Spinner size='sm' me="10px"/>} Confirm
                                        </Button>
                                    </>
                                }

                            </CardBody>
                        </Card>
                    </Box>
                    <Box w="55%" p="15px"  display={{base: "none", md: "flex"}}>
                        <Image src={drsImg} w="full" maxW="full" />
                    </Box>
                </HStack>
            </GridItem>
            <GridItem area={'footer'} justifyContent="center">
                <Box maxW="768px" w="full" borderTop="1px solid" borderColor="gray.200" mx="auto">
                    <Text textAlign="center" fontSize="13px" pt="15px" color="gray">
                        Read our privacy policy
                    </Text>
                </Box>
            </GridItem>
        </Grid>
    )
}
