import { gql } from "@apollo/client";

export const GET_PATIENT_RESOURCES = gql`
    query {
        patient_resources (where: {center_id: {_eq: ${process.env.REACT_APP_CENTER_ID}}}){
            id
            image
            descriptor
            description
            briefly
            data
            title
        }
    }
`;

export const GET_PATIENT_RESOURCE_CATEGORIES = gql`
    query {
        patient_resources_categories (where: {center_id: {_eq: ${process.env.REACT_APP_CENTER_ID}}}){
                descriptor
                id
                title
                description
                patient_resources {
                    id
                    briefly
                    category_id
                    center_id
                    data
                    description
                    descriptor
                    image
                    title
                }
            }
    }
`;

export const ADD_PATIENT_RESOURCES = gql`
    mutation InsertPatientResources($title: String! $image: String! $descriptor: String! $briefly: String! $description: String! $data: jsonb!) {
        insert_patient_resources(objects: {center_id: ${process.env.REACT_APP_CENTER_ID}, briefly: $briefly, data: $data, description: $description, descriptor: $descriptor, image: $image, title: $title}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

