import {useMutation, useQuery} from "@apollo/client";
import {GET_PARAMS_FORM_STRUCTURE} from "../../queries";
import {Card, CardBody, Box, Heading, SimpleGrid, Text, VStack, Button} from "@chakra-ui/react";
import * as React from "react";
import {useEffect, useState} from "react";
import FormElementsContainer from "../../../../../../components/FromsElements/FormElementsContainer";
import {FormItem} from "../../../../../../interfaces/Interfaces";
import {ADD_FORM_DATA, UPDATE_FORM_DATA_BY_PK, UPDATE_TREATMENT_USER_SESSION_BY_PK} from "../../../queries";
import useCustomToast from "../../../../../../hooks/useCustomToast";
import useAuth from "../../../../../../hooks/useAuth";

const ParamsForm = (props:any) => {
    const [InsertFormData] = useMutation(ADD_FORM_DATA);
    const [updateFormDataByPk] = useMutation(UPDATE_FORM_DATA_BY_PK);
    const [updateTreatmentUserSessionByPk] = useMutation(UPDATE_TREATMENT_USER_SESSION_BY_PK)
    const [currentForm, setCurrentForm] = useState<any>(null);
    const [stateData, setStateData] = useState<any>({});
    const toast = useCustomToast();
    const session_id = props.treatment_session_id;
    const user = useAuth();
    const form_data = props.form_data;
    const [formId, setFormId] = useState<any>(null);
    const {error, loading, data, client} = useQuery(GET_PARAMS_FORM_STRUCTURE, {
        fetchPolicy: "no-cache",
        variables: {
            id: props.form_id
        }
    });
    const saveData = async () => {

        let hasError = false;

        if (formId) {
            let result = await updateFormDataByPk({
                variables: {
                    id: formId,
                    object: {
                        data: stateData
                    }
                }
            });

            if (!result.data?.update_form_data_by_pk?.id) {
                hasError = true;
            }
        } else {
            let result = await InsertFormData({
                variables: {
                    patient_id: props.patient_id,
                    data: stateData,
                    form_id: props.form_id,
                    user_id: user.user_id,
                    treatment_id: session_id
                }
            });

            if (result.data?.insert_form_data_one?.id) {
                setFormId(result.data?.insert_form_data_one?.id);
                await updateTreatmentUserSessionByPk({
                    variables: {
                        id: props.treatment_session_id,
                        object: {
                            form_id: result.data?.insert_form_data_one?.id
                        }
                    }
                });
            } else {
                hasError = true;
            }
        }

        if (!hasError) {
            toast.success('Data saved Successfully.')
        } else {
            toast.error('There is a problem on saving data!');
        }

    }

    useEffect(() => {
        if (form_data?.data) {
            setStateData(form_data.data);
            setFormId(form_data.id);
        }
    }, [form_data]);

    useEffect(() => {
        if (data?.form_schemas) {
            setCurrentForm(data.form_schemas[0].template.sections[0].forms[0]);
        }
    }, [data]);

    useEffect(() => {
        if (Object.keys(stateData).length === 0 && currentForm?.items) {
            let temp:any = {};
            for (let index in currentForm.items) {
                temp[currentForm.items[index].descriptor] = null;
            }
            setStateData(temp);
        }
    }, [currentForm])

    return(
        <Card w="full" border="1px solid" borderColor="gray.300" boxShadow="null" borderRadius="16px">
            <CardBody p="30px">
                <Heading fontSize="24px" fontWeight="700" mb="30px">Recordings</Heading>
                {currentForm?.items?.length > 0 &&
                    <SimpleGrid columns={{base: 1, md: 2}} spacingX='30px' spacingY='20px'>
                        <FormElementsContainer items={currentForm.items} recommendationData={props.recommendationData}
                            inputItemHandler={(value: string, item:FormItem) => {
                                let temp = JSON.parse(JSON.stringify(stateData));
                                temp[item.descriptor] = value;
                                setStateData(temp);
                            }} state={stateData}></FormElementsContainer>
                    </SimpleGrid>
                }
                <Button variant="btnMain" mt="30px" minW="150px"
                    onClick={() => {
                        saveData();
                    }}
                >Save</Button>
            </CardBody>
        </Card>
    )
}

export default ParamsForm;