import * as React from "react"
import {
    Box, Heading, Flex, Image, Text, Button, HStack, Container, Icon, Circle
} from "@chakra-ui/react"
import {Link as ReactRouterLink, useParams} from 'react-router-dom';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import logo from "../../../assets/images/logo.svg";
import {HamburgerIcon} from "@chakra-ui/icons";
import {useState} from "react";
import { FiGrid } from "react-icons/fi";
import { FaUserMd } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";

interface PageHeaderProps {

};

export const PageHeader = (props: PageHeaderProps) => {

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Container maxW="container.xl" h="full">
            <Flex h="full" w="full" justifyContent="space-between" alignItems="center"
                bg="white" borderRadius="12px" px="30px">
                <HStack alignItems="center" spacing="20px">
                    <Image width="56px" src={logo}></Image>
                    <Text fontSize="20px" fontWeight="500">Trinity Skin Clinic</Text>
                </HStack>

                <HStack alignItems="center" spacing="20px">
                    {/*<Icon fontSize="24px" as={FiGrid}></Icon>*/}
                    <Circle border="2px solid" borderColor="gray.200" size="44px" bg="white" cursor="pointer"
                        onClick={() => {
                            localStorage.clear();
                            window.location.href='/dashboard/login';
                        }}
                    >
                        <Icon fontSize="24px" color="gray.500" as={FiLogOut}></Icon>
                    </Circle>
                </HStack>
            </Flex>
        </Container>
    );

}