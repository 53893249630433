import {
    Button,
    Card,
    CardBody,
    Container,
    Heading,
    HStack,
    Icon,
    Square,
    Text,
    Box,
    VStack,
    Circle,
    SimpleGrid,
    Image,
    Stack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    IconButton,
    CardHeader,
    CardFooter,
    Badge
} from "@chakra-ui/react";
import {useApolloClient, useMutation} from "@apollo/client";
import {Link as ReactRouterLink, useParams} from "react-router-dom";
import {CheckIcon, ChevronLeftIcon, DeleteIcon, EditIcon, SmallCloseIcon, TimeIcon} from "@chakra-ui/icons";
import * as React from "react";
import {useLazyQuery} from "@apollo/client";
import {
    DELETE_TREATMENT_USER_SESSION_NOTE,
    DELETE_TREATMENT_USER_SESSION_PRESCRIPTION,
    GET_PATIENT_DATA,
    GET_TREATMENT_USER_DATA,
    UPDATE_TREATMENT_USER_SESSION_BY_PK
} from "../queries";
import {useEffect, useState} from "react";
import noImage from "../../../../assets/images/no-image.svg"
import noNote from "../../../../assets/images/appointment_empty.svg"
import noteImage from "../../../../assets/images/note.svg"
import noPrescription from "../../../../assets/images/no-prescription.svg"
import AddNoteModal from "./modals/AddNoteModal";
import AddPrescriptionModal from "./modals/AddPrescriptionModal";
import "./style.css";
import {getHRFormat, getHRFormatWithOutYear} from "../../../../helpers/DateUtil";
import {HiOutlineDotsHorizontal} from "react-icons/hi";
import PatientPictures from "./components/PatientPictures";
import {getPatientImageBasePath} from "../../../../helpers/S3Options";
import ParamsForm from "./components/ParamsForm";
import AddSessionModal from "../Treatment/modals/AddSessionModal";
import useCustomToast from "../../../../hooks/useCustomToast";
import Confirmation from "../../../../components/Confirmation";
import AddPictureModal from "./modals/AddPictureModal";

const Session = (props:any) => {
    let params = useParams();
    const client = useApolloClient();
    const borderBg = 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'10\' ry=\'10\' stroke=\'%23C9C9C9FF\' stroke-width=\'2\' stroke-dasharray=\'6%2c 10\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e")';

    const [getTreatmentUserData, {error, loading, data, called}] = useLazyQuery(GET_TREATMENT_USER_DATA, {
        fetchPolicy: "no-cache",
        variables: {
            id: params.tid
        }
    });

    const toast = useCustomToast();
    const [updateTreatmentUserSessionByPk] = useMutation(UPDATE_TREATMENT_USER_SESSION_BY_PK)
    const defaultSelectedPrescriptionData = {
        id: null,
        text: null,
        prescription_id: -1,
    };
    const defaultSelectedNoteData = {
        id: null,
        text: null,
    };
    const [addNoteModalIsOpen, setAddNoteModalIsOpen] = useState(false)
    const [addPrescriptionModalIsOpen, setAddPrescriptionModalIsOpen] = useState(false)
    const [editSessionModalIsOpen, setEditSessionModalIsOpen] = useState(false)
    const [recommendationData, setRecommendationData] = useState<any>({});
    const [currentSession, setCurrentSession] = useState<any>(null);
    const [selectedPrescription, setSelectedPrescription] = useState<any>(defaultSelectedPrescriptionData)
    const [selectedNote, setSelectedNote] = useState<any>(defaultSelectedNoteData)
    const [deleteTreatmentUserSessionNote] = useMutation(DELETE_TREATMENT_USER_SESSION_NOTE)
    const [deleteTreatmentUserSessionPrescription] = useMutation(DELETE_TREATMENT_USER_SESSION_PRESCRIPTION)
    const [deleteNoteModalIsOpen, setDeleteNoteModalIsOpen] = useState(false);
    const [deletePrescriptionModalIsOpen, setDeletePrescriptionModalIsOpen] = useState(false);
    useEffect(() => {
        if (params.tid) {
            getTreatmentUserData();
        }
    }, [params]);

    useEffect(() => {
        if (data && data.treatment_users_by_pk) {

            setRecommendationData({
                skin_type: data?.treatment_users_by_pk?.patient?.skin_type
            });

            data.treatment_users_by_pk.sessions.map((session:any, index:number) => {
                if (session.id == params.sid) {
                    setCurrentSession({...session, currentNumber: index + 1});
                }
            });
        }
    }, [data]);

    const getStatusBox = (status:string) => {
        if (status === 'PENDING') {
            return (
                <HStack px="12px" py="8px" bg="#F6F1E2" borderRadius="8px">
                    <TimeIcon me="5px" color="#BB993B"></TimeIcon>
                    <Text fontSize="14px" as="span" fontWeight="500" color="#BB993B">Pending</Text>
                </HStack>
            );
        } else if (status === 'CANCELED') {
            return (
                <HStack px="12px" py="8px" bg="#F6F1E2" borderRadius="8px">
                    <TimeIcon me="5px" color="#BB993B"></TimeIcon>
                    <Text fontSize="14px" as="span" fontWeight="500" color="#BB993B">Canceled</Text>
                </HStack>
            );
        } else if (status === 'DONE') {
            return (
                <HStack px="12px" py="8px" bg="#EEFAF2" borderRadius="8px">
                    <CheckIcon me="5px" color="#117A31"></CheckIcon>
                    <Text fontSize="14px" as="span" fontWeight="500" color="#117A31">Done</Text>
                </HStack>
            );
        } else {
            return (<></>);
        }
    }

    return (
        <Container w="full" maxW="container.xl" mt="35px">
            <HStack flexWrap="wrap">
                <Button as={ReactRouterLink} to={`/dashboard/patient/${params.pid}`} display="flex" bg="transparent" borderRadius="100px" h="48px"
                    w="120px" border="1px solid" borderColor="gray.300"
                >
                    <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
                    <Text fontSize="md" mx="10px">Back</Text>
                </Button>

                { currentSession &&
                    <HStack flex={1} spacing="5" flexWrap="wrap">
                        <Heading fontSize="30px" mx="15px">Session {currentSession?.currentNumber} ({data.treatment_users_by_pk.treatment.title})</Heading>

                        {currentSession.date &&
                            <Text fontWeight="500">{getHRFormatWithOutYear(new Date(currentSession.date))}</Text>
                        }

                        {currentSession.start_time &&
                            <Text fontWeight="500">{currentSession.start_time}</Text>
                        }

                        {currentSession.end_time && currentSession.start_time &&
                            <Text>-</Text>
                        }

                        {currentSession.end_time &&
                            <Text fontWeight="500"> {currentSession.end_time}</Text>
                        }

                        {currentSession.status &&
                            getStatusBox(currentSession.status)
                        }

                        <Button variant="btnNormal"
                            onClick={() => {
                                setEditSessionModalIsOpen(true);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </Button>
                        {currentSession.status !== 'DONE' &&
                            <HStack flex="1" justifyContent="end">
                                <Button variant="btnMain"
                                    onClick={async () => {
                                        let result = await updateTreatmentUserSessionByPk({
                                            variables: {
                                                id: params?.sid,
                                                object: {
                                                    status: 'DONE'
                                                }
                                            }
                                        });

                                        await client.refetchQueries({
                                            include: [GET_TREATMENT_USER_DATA],
                                        });
                                    }}
                                >Set as complete</Button>
                            </HStack>
                        }

                    </HStack>
                }
            </HStack>

            {currentSession &&
                <Box py="30px">
                    <PatientPictures
                        basePath={getPatientImageBasePath(data?.treatment_users_by_pk?.patient, data?.treatment_users_by_pk?.treatment?.descriptor, data?.treatment_users_by_pk?.id)}
                        session={currentSession}
                        treatment={data?.treatment_users_by_pk?.treatment}
                    ></PatientPictures>

                    <SimpleGrid mx="auto" mt="30px" w="full" maxW="full" minChildWidth={{
                        base: "100%", lg: "50%"
                    }}>
                        <Card mb="30px" me={{base: 0, lg: '15px'}} pb="15px"
                            border="1px solid" borderColor="gray.300" boxShadow="none" borderRadius="16px" position="relative">
                            <CardHeader display="flex" justifyContent="space-between">
                                <Heading fontSize="24px">Notes</Heading>
                                <Text fontSize="16px" fontWeight="500" color="#0065FF" as="span" cursor="pointer"
                                    onClick={() => {
                                        setSelectedNote(defaultSelectedNoteData);
                                        setAddNoteModalIsOpen(true);
                                    }}
                                >Add Note  +</Text>
                            </CardHeader>
                            <CardBody p="30px" pt="0" minH="200px" maxH={{base: "auto", md: "415px"}} overflowY="auto">
                                <VStack w="full" gap="4" h="full" justifyContent={currentSession.notes.length ? 'top' : 'center'}>
                                { !currentSession?.notes || currentSession.notes.length === 0 &&
                                    <>
                                        <Image w="66px" src={noNote}></Image>
                                        <Text fontSize="14px" fontWeight="400" color="#5C5C5C">There are currently no data</Text>
                                    </>
                                }
                                { currentSession.notes.map((note:any, index: number) =>
                                    <Card w="full" key={index} boxShadow="null" borderRadius="12px" border="1px solid"
                                        borderColor="gray.300" position="relative">
                                        <CardHeader h="1px" p={0} position="relative">
                                            <Image src={noteImage} position="absolute" right="25px" top="0"></Image>
                                        </CardHeader>
                                        <CardBody p="15px" pt="20px">
                                            <Text fontSize="15px" fontWeight="400" color="black">
                                                {note.text}
                                            </Text>
                                        </CardBody>
                                        <CardFooter p="15px" pt="0" justifyContent="space-between" alignItems="center">
                                            <Text fontSize="14px" fontWeight="400" color="#2E2E2E">{getHRFormat(new Date(note.created_at))}</Text>
                                            <Menu>
                                                <MenuButton w="32px" h="32px" minW="32px"
                                                    as={IconButton}
                                                    aria-label='Options'
                                                    icon={<HiOutlineDotsHorizontal size="20" color="black" />}
                                                    variant='link'
                                                    border="0px"
                                                />
                                                <MenuList>
                                                    <MenuItem fontSize="14px" icon={<EditIcon fontSize="16px" />}
                                                        onClick={() => {
                                                            setSelectedNote({
                                                                id: note.id,
                                                                text: note.text
                                                            });
                                                            setAddNoteModalIsOpen(true)
                                                        }}
                                                    >
                                                        Edit Note
                                                    </MenuItem>
                                                    <MenuItem fontSize="14px" icon={<DeleteIcon fontSize="16px" />}
                                                        onClick={async () => {
                                                            setSelectedNote({
                                                                id: note.id,
                                                                text: note.text
                                                            })
                                                            setDeleteNoteModalIsOpen(true);
                                                        }}
                                                    >
                                                        Delete Note
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </CardFooter>
                                    </Card>
                                ) }
                                </VStack>
                            </CardBody>
                        </Card>

                        <Card mb="30px" ms={{base: 0, lg: '15px'}} pb="15px"
                            border="1px solid" borderColor="gray.300" boxShadow="null" borderRadius="16px"
                        >
                            <CardHeader display="flex" justifyContent="space-between">
                                <Heading fontSize="24px">Prescriptions</Heading>
                                <Text fontSize="16px" fontWeight="500" color="#0065FF"  cursor="pointer"
                                    onClick={() => {
                                        setSelectedPrescription(defaultSelectedPrescriptionData);
                                        setAddPrescriptionModalIsOpen(true);
                                    }}
                                >New Prescription  +</Text>
                            </CardHeader>
                            <CardBody p="30px" pt="0" minH="200px" maxH={{base: "auto", md: "415px"}} overflowY="auto">
                                <VStack h="full" justifyContent={currentSession.prescriptions.length ? 'top' : 'center'}>
                                { !currentSession?.prescriptions || currentSession.prescriptions.length === 0 &&
                                    <>
                                        <Image w="76px" src={noPrescription}></Image>
                                        <Text fontSize="14px" fontWeight="400" color="#5C5C5C">There are currently no data</Text>
                                    </>
                                }
                                { currentSession.prescriptions.map((prescription:any, index: number) =>
                                    <Card w="full" key={index} boxShadow="null" borderRadius="12px" border="1px solid"
                                        borderColor="gray.300" position="relative">
                                        <CardBody p="15px" pt="20px">
                                            <Text fontSize="15px" fontWeight="400" color="black">
                                                {prescription.text}
                                            </Text>
                                        </CardBody>
                                        <CardFooter p="15px" pt="0" justifyContent="space-between" alignItems="center">
                                            <Text fontSize="14px" fontWeight="400" color="#2E2E2E">{getHRFormat(new Date(prescription.created_at))}</Text>
                                            <Menu>
                                                <MenuButton w="32px" h="32px" minW="32px"
                                                    as={IconButton}
                                                    aria-label='Options'
                                                    icon={<HiOutlineDotsHorizontal size="20" color="black" />}
                                                    variant='link'
                                                    border="0px"
                                                />
                                                <MenuList>
                                                    <MenuItem fontSize="14px" icon={<EditIcon fontSize="16px" />}
                                                        onClick={() => {
                                                            setSelectedPrescription({
                                                                id: prescription.id,
                                                                text: prescription.text,
                                                                prescription_id: prescription.treatment_prescription_id ?? -1
                                                            });
                                                            setAddPrescriptionModalIsOpen(true)
                                                        }}
                                                    >
                                                        Edit Prescription
                                                    </MenuItem>
                                                    <MenuItem fontSize="14px" icon={<DeleteIcon fontSize="16px" />}
                                                        onClick={async () => {
                                                            setSelectedPrescription({
                                                                id: prescription.id,
                                                                text: prescription.text,
                                                                prescription_id: prescription.treatment_prescription_id ?? -1
                                                            })

                                                            setDeletePrescriptionModalIsOpen(true);
                                                        }}
                                                    >
                                                        Delete Prescription
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </CardFooter>
                                    </Card>
                                ) }
                                </VStack>
                            </CardBody>
                        </Card>
                    </SimpleGrid>

                    { deleteNoteModalIsOpen &&
                        <Confirmation isOpen={deleteNoteModalIsOpen}
                            closeAction={() => {
                                setDeleteNoteModalIsOpen(false)
                            }}
                            acceptAction={async () => {
                                let result = await deleteTreatmentUserSessionNote({
                                variables: {
                                    id: selectedNote.id,
                                }
                                });

                                if (result.data?.delete_treatment_user_session_notes_by_pk?.id) {
                                  toast.success('Note deleted successfully');
                                }

                                await client.refetchQueries({
                                  include: [GET_TREATMENT_USER_DATA],
                                });

                                setDeleteNoteModalIsOpen(false)
                            }}
                        >
                            <Text>Are you sure to delete this Note?</Text>
                        </Confirmation>
                    }

                    { deletePrescriptionModalIsOpen &&
                        <Confirmation isOpen={deletePrescriptionModalIsOpen}
                            closeAction={() => {
                              setDeletePrescriptionModalIsOpen(false)
                            }}
                            acceptAction={async () => {
                                let result = await deleteTreatmentUserSessionPrescription({
                                    variables: {
                                        id: selectedPrescription.id,
                                    }
                                });

                                if (result.data?.delete_treatment_user_session_prescription_by_pk?.id) {
                                    toast.success('Prescription deleted successfully');
                                }

                                await client.refetchQueries({
                                    include: [GET_TREATMENT_USER_DATA],
                                });

                                setDeletePrescriptionModalIsOpen(false)
                            }}
                        >
                            <Text>Are you sure to delete this Prescriptions?</Text>
                        </Confirmation>
                    }

                    {data?.treatment_users_by_pk?.treatment?.form_id &&
                        <ParamsForm recommendationData={recommendationData}
                            patient_id={params.pid}
                            form_id={data?.treatment_users_by_pk?.treatment?.form_id}
                            treatment_session_id={params.sid}
                            form_data={currentSession?.form}
                        ></ParamsForm>
                    }
                    {addNoteModalIsOpen &&
                        <AddNoteModal isOpen={addNoteModalIsOpen} defaultState={selectedNote}
                            closeAction={() => {
                                setSelectedNote(null);
                                setAddNoteModalIsOpen(false)
                            }}
                            addAction={async () => {
                                await client.refetchQueries({
                                    include: [GET_TREATMENT_USER_DATA],
                                });
                                setSelectedNote(null);
                                setAddNoteModalIsOpen(false);
                            }}
                        ></AddNoteModal>
                    }

                    {addPrescriptionModalIsOpen &&
                        <AddPrescriptionModal defaultState={selectedPrescription}
                            prescriptions={data?.treatment_users_by_pk?.treatment?.prescriptions} isOpen={addPrescriptionModalIsOpen}
                            closeAction={() => {
                                setAddPrescriptionModalIsOpen(false)
                                setSelectedPrescription(null);
                            }}
                            addAction={async () => {
                                await client.refetchQueries({
                                    include: [GET_TREATMENT_USER_DATA],
                                });
                                setSelectedPrescription(null);
                                setAddPrescriptionModalIsOpen(false);
                            }}
                        ></AddPrescriptionModal>
                    }

                    { editSessionModalIsOpen &&
                        <AddSessionModal isOpen={editSessionModalIsOpen}
                            closeAction={async () => {
                                setEditSessionModalIsOpen(false)
                            }}
                            saveAction={async() => {
                                await client.refetchQueries({
                                    include: [GET_TREATMENT_USER_DATA],
                                });
                            }}
                            defaultState={currentSession}
                        ></AddSessionModal>
                    }
                </Box>
            }
        </Container>
    );
}

export default Session;