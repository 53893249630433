import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import ModalBox from "../../ModalBox";
import {Box, Heading, HStack, Link, Text, Flex, NumberInput, NumberInputField} from "@chakra-ui/react";
import TextBox from "../../FromsElements/TextBox";
import {FormItem} from "../../../interfaces/Interfaces";
import {setFormData} from "../../../pages/Consultation/slice";
import {setStep, setOtpCode, resetResendTimeLeft, decreaseResendTimeLeft} from "../slice";
import {EditIcon} from "@chakra-ui/icons";

interface InputOtpProps {
    state: any,
    onExitProcess?: any,
    onContinueProcess?: any,
    dataHandler?: any,
    checkErrorMessage?: string
}

const InputOtp = (props: InputOtpProps) => {
    const mobileAuthenticatorState = useSelector((state: RootState) => state.mobileAuthenticator)
    const dispatch = useDispatch()
    const state = props.state;
    const onExitProcess = props.onExitProcess;
    const dataHandler = props.dataHandler;
    const onContinueProcess = props.onContinueProcess;
    const checkErrorMessage = props.checkErrorMessage;

    const [cleared, setCleared] = useState(false)

    const otpInputs:Array<FormItem> = [
        {
            title: "",
            descriptor: "otp_1",
            values: {}
        },
        {
            title: "",
            descriptor: "otp_2",
            values: {}
        },
        {
            title: "",
            descriptor: "otp_3",
            values: {}
        },
        {
            title: "",
            descriptor: "otp_4",
            values: {}
        },
        {
            title: "",
            descriptor: "otp_5",
            values: {}
        },
    ];

    useEffect(() => {
        if (mobileAuthenticatorState.resend_time_left > 0) {
            const interval = setInterval(() => {
                dispatch(decreaseResendTimeLeft());
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [mobileAuthenticatorState.resend_time_left]);

    const getHumanReadableResendTimeLeft = () => {
        let result = {
            m: 0,
            s: 0
        };

        if (mobileAuthenticatorState.resend_time_left > 0) {
            result.m = Math.floor(mobileAuthenticatorState.resend_time_left / 60);
            result.s = mobileAuthenticatorState.resend_time_left - (result.m * 60);
        }

        return result.m.toString().padStart(2, "0") + ':' + result.s.toString().padStart(2, "0");
    }

    return (
        <Box>
            <Heading size="md" textAlign="center">Verification Code</Heading>
            <Text textAlign="center" p="15px" mx="15px">
                To verify your number, please complete the following step.
            </Text>
            <HStack justifyContent="space-between">
                <Text>Enter the 5-digit code sent to</Text>
                <Flex ps="10px" justifyContent="center" alignItems="center" color="blue.600">
                    {mobileAuthenticatorState.mobile}
                    <Link ps="5px" onClick={() => {
                        dispatch(setStep(0));
                    }}>
                        <EditIcon></EditIcon>
                    </Link>
                </Flex>
            </HStack>
            <HStack w="full" justifyContent="center" mt="25px">
                { otpInputs.map((otpItem, index) => {
                    return (
                        <NumberInput max={9} min={0} p="5px" key={otpItem.descriptor}
                             onKeyDown={(e) => {
                                 if (e.key === 'End' || e.key === 'Home') {
                                     return e.preventDefault();
                                 }
                             }}

                             onKeyUp={(e) => {
                                 if (e.key === 'Backspace' || e.key === 'Delete') {
                                     if (cleared) {
                                         if (index - 1 > -1) {
                                             if (document.querySelectorAll('.otp-field')[index - 1] !== undefined) {
                                                 (document.querySelectorAll('.otp-field')[index - 1] as HTMLInputElement)?.focus();
                                             }
                                         }
                                         setCleared(false);
                                     } else {
                                         setCleared(true);
                                     }
                                 } else if (e.key !== 'Tab') {
                                     if ((document.querySelectorAll('.otp-field')[index + 1] as HTMLInputElement)?.value.length > 0 && index + 1 < otpInputs.length) {
                                         if (document.querySelectorAll('.otp-field')[index + 1] !== undefined) {
                                             (document.querySelectorAll('.otp-field')[index + 1] as HTMLInputElement)?.focus();
                                         }
                                     }
                                 }
                             }}

                             onChange={(value) => {
                                 let otpCode = '';
                                 document.querySelectorAll('.otp-field').forEach((element) => {
                                     otpCode = otpCode + '' + (element as HTMLInputElement)?.value;
                                 });

                                 dispatch(setOtpCode(otpCode));

                                 if (value.length > 0 && index + 1 < otpInputs.length) {
                                     if (document.querySelectorAll('.otp-field')[index + 1] !== undefined) {
                                         (document.querySelectorAll('.otp-field')[index + 1] as HTMLInputElement)?.focus();
                                     }
                                 }
                             }}

                             onFocus={(e) => {
                                 (document.querySelectorAll('.otp-field')[index] as HTMLInputElement)?.select();
                             }}
                        >
                            <NumberInputField w="50px" h="50px" p="0" maxLength={1} textAlign="center" className="otp-field"

                            />
                        </NumberInput>
                    );
                }) }
            </HStack>
            {checkErrorMessage && checkErrorMessage.length > 0 &&
                <Text color="red.500">{checkErrorMessage}</Text>
            }
            <Box textAlign="center" mt="10px">
                { mobileAuthenticatorState.resend_time_left > 0 &&
                    <Text>
                        {getHumanReadableResendTimeLeft()} To resend code
                    </Text>
                }

                { mobileAuthenticatorState.resend_time_left <= 0 &&
                    <Link onClick={() => {
                        dispatch(resetResendTimeLeft());
                    }}>Tap To Resend Code</Link>
                }
            </Box>
        </Box>
    );
}

export default InputOtp;