import {HStack, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr} from "@chakra-ui/react";
import {TableFieldInterface} from "../../../interfaces/Interfaces";
import {useEffect} from "react";
import {getDateTimeHRFormat} from "../../../helpers/DateUtil";

interface ListTableProps {
    data: Array<any>,
    fields: Array<TableFieldInterface>,
    page?: number,
    perPage?: number,
    total?: number,
    loading?: boolean,
    hasHeader?: boolean|null,
    hasFooter?: boolean|null,
    hasPagination?: boolean|null,
    actionField?: any|null,
}
const ListTable = (props:ListTableProps) => {

    const getIndexNumber = (index: number, page: number, perPage: number) => {
        return ((page - 1) * perPage) + index;
    }

    const getTdValue = (index:number, field:TableFieldInterface, record: any) => {
        if (field?.expression === 'INDEX_NUMBER_FIELD') {
            return getIndexNumber(index, props.page ?? 1, props.perPage ?? 20)
        }

        if (field?.expression === 'ACTION_FIELD' && props.actionField) {
            return props.actionField(record.id)
        }

        if (field?.expression === 'DATE_TIME_HR' && typeof field.descriptor === 'string') {
            return getDateTimeHRFormat(new Date(record[field.descriptor]));
        }

        if ((!!field.descriptor) && (field.descriptor.constructor === Array)) {
            let value = '';
            for (let descriptor in field.descriptor) {
                value += ' ' + record[field.descriptor[descriptor]]
            }

            return value;
        }

        if (typeof field.descriptor === 'string') {
            return record[field.descriptor];
        }
    }

    return (
        <TableContainer maxW="full" overflowX="auto">

            { props?.loading &&
                <HStack w="full" h="100px" justifyContent="center" alignItems="center">
                    <Spinner fontSize="24px"></Spinner>
                </HStack>
            }

            {!props?.loading &&
            <Table>
                { (typeof  props.hasHeader === 'undefined' || props?.hasHeader) &&
                    <Thead borderBottom="2px solid" borderBottomColor="#C9C9C9" bg="#F5F5F5" h="56px">
                        <Tr>
                            {props.fields.map((field:TableFieldInterface, findex: number) => <Th key={'th' + findex + field.descriptor} fontWeight="700" fontSize="16px">{field.title}</Th>)}
                        </Tr>
                    </Thead>
                }
                <Tbody>
                    { props.data.map((record, rindex) =>
                        <Tr key={'tr' + rindex} h="56px" bg={rindex % 2 ? '#F8F8F8' : 'transparent'}>
                            {props.fields.map((field:TableFieldInterface, findex: number) => <Td  key={'td' + findex + field.descriptor} fontWeight="400" fontSize="14px">{getTdValue(rindex + 1, field, record)}</Td>)}
                        </Tr>
                    ) }

                    {props.data.length === 0 &&
                        <Tr>
                            <Td colSpan={props.fields.length} textAlign="center"> No records! </Td>
                        </Tr>
                    }
                </Tbody>
                {  props?.hasFooter &&
                    <Tfoot borderBottom="2px solid" borderBottomColor="#C9C9C9" bg="#F5F5F5" h="56px">
                        <Tr>
                            {props.fields.map((field:TableFieldInterface, findex: number) => <Th key={'fth' + field.descriptor} fontWeight="700" fontSize="16px">{field.title}</Th>)}
                        </Tr>
                    </Tfoot>
                }
            </Table>
            }
        </TableContainer>
    );
}
export default ListTable;