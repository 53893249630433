import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
    container: {
        alignItems: 'flex-start'
    },
    control: {
        marginTop: '4px',
        borderRadious: "3px",
        borderColor: "#ADADAD",
        _checked: {
            bg: "#0065FF",
            borderColor: "#0065FF"
        },
        _hover: {
            _checked: {
                bg: "#0065FF",
                borderColor: "#0065FF"
            },
        }
    },
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })