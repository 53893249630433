import * as React from "react"
import {
    Box,
    SimpleGrid,
    Flex,
    Image,
    Text,
    Container,
    InputGroup,
    InputRightAddon,
    Button,
    Input,
    HStack,
    GridItem,
    Grid,
    Square,
    Icon, VStack, Heading
} from "@chakra-ui/react"

import {Link as ReactRouterLink, useParams} from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import imageDivider from "../../../assets/images/patient_resources/title_divider.svg";
import TextBox from "../../FromsElements/TextBox";
import ButtonBox from "../../FromsElements/ButtonBox";
import logo from "../../../assets/images/logo.svg";
import footerBg from "../../../assets/images/footer-bg.svg";
import logoWithText from "../../../assets/images/logo-with-title.svg";
import { FiPhone } from "react-icons/fi";
import { IoMdTime } from "react-icons/io";
import { GrLocation } from "react-icons/gr";
interface PageFooterProps {

};

export const PageFooter = (props: PageFooterProps) => {

    return (
        <Container minW="full" w="full" p="0"  backgroundImage={footerBg} backgroundPosition="top center"
            backgroundRepeat="no-repeat" backgroundSize={{base: "auto", xl: "contain"}} position="relative"
        >

            <Box position="absolute" top="200px" left="40px">
                <Square bg="gray.100" size="50px" position="absolute" left="0px"></Square>
                <Square bg="gray.100" size="30px" position="absolute" left="50px" top="50px"></Square>
            </Box>
            <Box position="absolute" top="300px" right="40px">
                <Square bg="gray.100" size="36px" position="absolute" right="0px"></Square>
                <Square bg="gray.100" size="24px" position="absolute" right="6px" top="50px"></Square>
                <Square bg="gray.100" size="45px" position="absolute" right="45px" top="36px"></Square>
            </Box>

            <Flex py="40px" px="30px" minW="full" h="auto" w="full" minH="100px" alignItems="center" justifyContent="center">
                <Image src={logoWithText} w="200px"></Image>
            </Flex>

            <Flex maxW="container.lg" mx="auto" direction="column" mt="30px" px="30px"  position="relative">

                <SimpleGrid pt="25px" w="full" maxW="full" minChildWidth="300px" justifyItems={{base: "start", md: "stretch"}}>
                    <HStack alignItems="top" mb="30px" justifyContent="start">
                        <Square size="56px" borderRadius="12px" bg="white" border="1px solid" borderColor="gray.200">
                            <Icon fontSize="20px" as={FiPhone}></Icon>
                        </Square>
                        <VStack px="10px" spacing="1" alignItems="start">
                            <Heading fontSize="16px" fontWeight="700">Contact us</Heading>
                            <Text fontWeight="400" fontSize="14px" color="gray.500">(08) 9091 8707</Text>
                            <Text fontWeight="400" fontSize="14px" color="gray.500">info@trinityskinclinic.com</Text>
                        </VStack>
                    </HStack>
                    <HStack alignItems="top" mb="30px"  justifyContent="end">
                        <Square size="56px" borderRadius="12px" bg="white" border="1px solid" borderColor="gray.200">
                            <Icon fontSize="20px" as={IoMdTime}></Icon>
                        </Square>
                        <VStack px="10px" spacing="1" alignItems="start">
                            <Heading fontSize="16px" fontWeight="700">Working Hours</Heading>
                            <Text fontWeight="400" fontSize="14px" color="gray.500">Monday-Friday 9:00 am - 5:00 pm</Text>
                            <Text fontWeight="400" fontSize="14px" color="gray.500">Saturday-Sunday Closed</Text>
                        </VStack>
                    </HStack>
                </SimpleGrid>
                <HStack alignItems="top" mb="30px">
                    <Square size="56px" borderRadius="12px" bg="white" border="1px solid" borderColor="gray.200">
                        <Icon fontSize="20px" as={GrLocation}></Icon>
                    </Square>
                    <VStack px="10px" spacing="1" alignItems="start">
                        <Heading fontSize="16px" fontWeight="700">Address</Heading>
                        <Text fontWeight="400" fontSize="14px" color="gray.500">Trinity Skin Clinic, 24 Picaddilly, Kalgoorlie 6430 WA</Text>
                    </VStack>
                </HStack>
            </Flex>

            <Box w="full"  borderTop="1px solid" borderColor="gray.200" py="40px" mx="auto" maxW="container.xl">
                <Text fontSize="14px" fontWeight="400" color="gray.500" textAlign="center">© {(new Date()).getFullYear()}, All Rights Reserved</Text>
            </Box>
        </Container>
    );

}