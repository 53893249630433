import React from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Box, FormControl, FormLabel, Input} from "@chakra-ui/react";
import MobileAuthorizer from "../../MobileAuthorizer"
import AddressBox from "../AddressBox/index"
import Overview from "../Overview";

const CustomComponentContainer = (props: FormItemProps) => {
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let item = props.item;
    let validator = props.validator;

    return (
        <>
            {item.component === 'MobileAuthorizer' &&
                <MobileAuthorizer validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></MobileAuthorizer>
            }

            {item.component === 'AddressBox' &&
                <AddressBox validator={validator} item={item} inputItemHandler={inputItemHandler} state={state}></AddressBox>
            }

            {item.component === 'Overview' &&
                <Overview item={item} inputItemHandler={inputItemHandler} state={state}></Overview>
            }
        </>
    );
}

export default CustomComponentContainer;
