import React, {useEffect, useState} from 'react';
import {
    Box,
    Flex,
    Text,
    Circle,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    Progress,
    StepTitle,
    HStack
} from "@chakra-ui/react";
import {StepperProgressBarProps} from "../../interfaces/Interfaces"
import {ArrowBackIcon} from "@chakra-ui/icons";
import {useDispatch} from "react-redux";

const StepperProgressBar = (props: StepperProgressBarProps) => {
    let sections = props.sections;
    let activeSection = props.activeSection;
    let activeStep = props.activeStep;
    let nextStep = props.nextStep;
    let prevStep = props.prevStep;
    const dispatch = useDispatch()
    const [totalForms, setTotalForms] = useState(0);
    const [steps, setSteps] = useState<any[]>([]);

    const hasBackButton = () => {
        if (activeSection === 1 && activeStep === 1) {
            return false;
        }

        return true;
    }

    useEffect(() => {

    }, [activeSection, activeStep]);

    return (
        <Flex justifyContent="space-between" h="65px" w="100%" my="30px" position="relative">
            <Box w="60px">
                {hasBackButton() &&
                    <Circle size='40px' bg='gray.50' color='white' boxShadow="md" cursor="pointer"
                        onClick={() => {
                            if (prevStep) {
                                dispatch(prevStep())
                            }
                        }}
                    >
                        <ArrowBackIcon fontSize="xl" color="gray.500"></ArrowBackIcon>
                    </Circle>
                }
            </Box>

            <Text position="absolute" left="55px" top="-15px">{sections[activeSection - 1].title}</Text>

            <HStack w="full">
                {sections.map((sct, index) => {
                    let w = '0';
                    let border = '';
                    let boxShadow = '';
                    if (index < activeSection - 1) {
                        w = "full";
                    } else if (index === activeSection - 1) {
                        let total = sections[activeSection - 1].forms.length;
                        w = (100 * (activeStep - 1) / total) + '%';
                        // border='1px solid';
                        boxShadow='base';
                    }

                    return (
                        <Box key={index} w="full" h="6px" position="relative" bg="#E6E6E6" border={border}
                             boxShadow={boxShadow} borderRadius="1px"
                             borderColor="blue.300">
                            <Box position="absolute" left="0" top="0" bottom="0" bg="#267CFF" w={w}></Box>
                        </Box>
                    )
                })}
            </HStack>
        </Flex>
    );
}

export default StepperProgressBar;