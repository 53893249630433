import {Box, VStack, Text, HStack, Circle, Image, Tooltip} from "@chakra-ui/react";

interface SkinTypeMetaDataProps {
    score: number,
    meta_data: any
}
const SkinType = (props: SkinTypeMetaDataProps) => {

    const data = props.meta_data;
    const calcWidth = (score: number, start: number, end: number) => {
        if (score === 0 || score < start) {
            return 0;
        }

        if (score > end) {
            return 100;
        }

        return 100 * (score - start) / (end - start);
    }

    const isOpen = (score: number, start: number, end: number) => {
        if (score === 0 || score < start) {
            return '';
        }

        if (score > end) {
            return '';
        }

        if (score >= start && score <= end) {
            return score.toString();
        }

        return '';
    }

    return (
        <VStack flex="1" minW="167px">
            <Box h="6px" w="full" bg="#D9D9D9">
                <Tooltip label={isOpen(props.score, data.start, data.end)} hasArrow color="white"
                    placement='top-end' isOpen={isOpen(props.score, data.start, data.end).length > 0}
                    fontWeight="700" fontSize="18px" bg="#267CFF" borderRadius="61px"
                    me="-25px"
                >
                    <Box h="full" bg="#267CFF" w={calcWidth(props.score, data.start, data.end) + '%'}></Box>
                </Tooltip>
            </Box>

            <Text fontWeight="500">
                {data.start}
                {data.end ? ' - ' + data.end : '+'}
            </Text>

            <HStack mt="15px">
                <Text fontWeight="500" fontSize="15px">{data.title}</Text>

                {data.color &&
                    <Circle size="24px" bg={data.color}></Circle>
                }
            </HStack>
            {data.images?.length > 0 &&
                <Tooltip hasArrow label={data.description} bg='#E0EDFF' borderRadius="8px" py="5px" px="15px" color="#2E2E2E" fontWeight="400" >
                    <HStack mt="10px">
                        {data.images.map((image: string, index: number) => {
                            return (
                                <Image h="64px" w="64px" ms={index > 0 ? '-32px' : '0'} borderRadius="full" key={index} src={image}></Image>
                            );
                        })}
                    </HStack>
                </Tooltip>
            }

        </VStack>
    );
}

export default SkinType;