import * as React from "react"
import {
    Box, Heading, Flex, Image, Text, Container, InputGroup, InputRightAddon, Button, Input, HStack, GridItem, Grid
} from "@chakra-ui/react"
import {Link as ReactRouterLink, useParams} from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import tempIcon from "../../../assets/images/doctor.png";
import imageDivider from "../../../assets/images/patient_resources/title_divider.svg";
import TextBox from "../../FromsElements/TextBox";
import ButtonBox from "../../FromsElements/ButtonBox";
import logo from "../../../assets/images/logo.svg";
interface PageFooterProps {

};

export const PageFooter = (props: PageFooterProps) => {

    return (
        <></>
    );

}