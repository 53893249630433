import * as React from "react"
import {
    Box, Heading, Flex, Image, Text, VStack, Button
} from "@chakra-ui/react"

import otpDoneImage from "../../assets/images/otp-done.svg";
import {Link as ReactRouterLink, useParams} from 'react-router-dom';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'

export const FinalPage = () => (
    <VStack w="full">
        <Box>
            <Image src={otpDoneImage} w={{base: '300px', sm: '350px'}}></Image>
        </Box>
        <Heading size="lg">Information was sent successfully</Heading>
        <Text textAlign="center" fontSize="18px">Thank you for taking the time to submit the form, We will check your
            information and contact you.</Text>
        <ChakraLink as={ReactRouterLink} to='/' w="full" p='35px'>
            <Button w="full" bg="white" border="1px solid" borderColor="gray.100" borderRadius="20px">Back To Home</Button>
        </ChakraLink>
    </VStack>
)
