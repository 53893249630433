import React from "react";
import { useToast } from '@chakra-ui/react'
const useCustomToast = () => {
    const toast = useToast();
    const toastIdRef = React.useRef();
    const error = (message: string) => {
        toast({
            description: message,
            status: 'error',
            isClosable: true,
            duration: 5000
        })
    }

    const success = (message: string) => {
        toast({
            description: message,
            status: 'success',
            isClosable: true,
            duration: 5000
        })
    }

    return {error: error, success: success}
}

export default useCustomToast;