import { buttonAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle, defineStyleConfig } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(buttonAnatomy.keys)

const btnLink = defineStyle({
    background: "transparent",
    borderRadius: '100px',
    _hover: {
        background: "gray.100",
    }
})

const btnMain = defineStyle({
    background: "#181D40",
    borderRadius: '100px',
    color: "white",
    fontSize: "16px",
    fontWeight: "500",
    h: "48px",
    _hover: {
        background: "#262e69",
        _disabled: {
            background: "#585b79",
        },
    },
    _disabled: {
        background: "#585b79",
        color: "gray.400",
        _hover: {
            background: "#585b79",
        },
    }
})
const btnNormal = defineStyle({
    background: "#F1F1F5",
    borderRadius: '100px',
    color: "#575757",
    fontSize: "16px",
    fontWeight: "500",
    h: "48px",
    _hover: {
        background: "#c9c9d2",
    }
})

const btnWarning = defineStyle({
    background: "#ffcc51",
    borderRadius: '100px',
    color: "white",
    fontSize: "16px",
    fontWeight: "500",
    h: "48px",
    _hover: {
        background: "#deae1c",
    }
})

const baseStyle = definePartsStyle({

})

export const buttonTheme = defineStyleConfig({
    variants: { btnLink, btnMain, btnNormal, btnWarning },
})