import React, {useEffect} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Box, FormControl, FormLabel, Input, HStack, useRadio, useRadioGroup} from "@chakra-ui/react";
import RadioCard from "../RadioCard/index"
import {useDispatch} from "react-redux";
const RadioCardGroup = (props: FormItemProps) => {
    const options = props.item.sub_items;
    // const options = ['react', 'vue', 'svelte']
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: item.descriptor,
        defaultValue: state[item.descriptor] ? state[item.descriptor] : item?.default_value,
        onChange: (value) => {
            if (inputItemHandler) {
                inputItemHandler(value, item);
            }
        },
    })

    const group = getRootProps()
    return (
        <Box>
            <HStack {...group}>
                {options && options.map((value) => {
                    const radio = getRadioProps({ value: value.text })

                    return (
                        <RadioCard key={item.descriptor + '_' + value.descriptor} {...radio}>
                            {value.text}
                        </RadioCard>
                    )
                })}
            </HStack>
            {!item?.hide_validation_error && IsInvalidMessage}
        </Box>
    );
}

export default RadioCardGroup;
