import * as React from "react"
import {
    Box, Heading, Text, Grid, GridItem, Container, Flex, Link as ChakraLink, Center, Button, VStack, Spacer
} from "@chakra-ui/react"

import {FormContainer} from "../../components/FromsElements/FormContainer";

import { ArrowBackIcon } from '@chakra-ui/icons'
import {Link as ReactRouterLink, useLocation} from 'react-router-dom'
import StepperProgressBar from "../../components/StepperProgressBar/indexV2";
import {SectionObject, Form, PatientDataInterface} from "../../interfaces/Interfaces"
import type { RootState } from '../../store/Store'

import formSchema from "../../TestData/consultationForm.json"
import {useEffect, useState} from "react";

import { useSelector, useDispatch } from 'react-redux'
import {nextStep, prevStep, init, setFormData, extendForms, setPatientData, setFormFinished, inCreaseSubmitTries, setValidator} from './slice'
import ButtonBox from "../../components/FromsElements/ButtonBox";
import { gql, useQuery } from "@apollo/client";
import {GET_CONSULTATION_FORM_STRUCTURE, ADD_PATIENT_DATA, ADD_CONSULTATION_DATA, UPDATE_PATIENT_DATA} from "./queries";
import {useMutation} from "@apollo/client";

import {getDbFormat} from "../../helpers/DateUtil"
import {FinalPage} from "./FinalPage";
import SimpleReactValidator from 'simple-react-validator';
import generateString from "../../helpers/StringUtil";
import useCustomToast from "../../hooks/useCustomToast";
export const Consultation = () => {

    const consultationState = useSelector((state: RootState) => state.consultation)
    const dispatch = useDispatch()
    const {error, loading, data, client} = useQuery(GET_CONSULTATION_FORM_STRUCTURE, {
        fetchPolicy: "no-cache"
    });
    const [isReadyToSubmit, setIsReadyToSubmit] = useState(true);
    let {state} = useLocation();

    const [preState, setPreState] = useState<any>(null);
    const [InsertPatient] = useMutation(ADD_PATIENT_DATA)
    const [UpdatePatient] = useMutation(UPDATE_PATIENT_DATA)
    const [InsertFormData] = useMutation(ADD_CONSULTATION_DATA)

    const patientIndexes = ['first_name', 'last_name'];

    const addPatientData = async () => {
        let result = await InsertPatient({
            variables: {
                ...consultationState.patient_data
            }
        });

        return result;
    }

    const updatePatientData = async () => {
        let result = await UpdatePatient({
            variables: {
                ...consultationState.patient_data
            }
        });

        return result;
    }

    useEffect(() => {
        if (state && !preState) {
            setPreState(state);
        }
    }, [state]);

    useEffect(() => {
        if (preState && consultationState.formsData) {
            Object.keys(preState).map((key) => {
                if (preState[key]) {
                    dispatch(setFormData({
                        "id" : key,
                        "value" : preState[key]
                    }))
                }

            });

            setPreState(null);
        }
    }, [consultationState.formsData]);

    const addFormData = async () => {
        let result = await InsertFormData({
            variables: {
                patient_id: consultationState.patient_data.id,
                data: consultationState.formsData
            }
        });

        return result;
    }


    useEffect(() => {
        if (consultationState.currentForm && !consultationState.currentForm.validator) {
            dispatch(setValidator(new SimpleReactValidator({
                element: (message:string) => {return(<Text fontSize="sm" color="red.500">{message}</Text>)}
            })));
        }
    }, [consultationState.currentForm]);


    useEffect(() => {
        if (data?.form_schemas && data?.form_schemas?.length > 0) {
            let formTemplate = data.form_schemas[0]?.template;
            let tempSection: SectionObject[] = [];

            formTemplate.sections.map((item:SectionObject) => {
                tempSection.push({
                    title: item.title,
                    schema: item.schema,
                    descriptor: item.descriptor,
                    selected: true,
                    isCurrent: true,
                    forms: item.forms
                });
            });

            dispatch(init({
                sections: tempSection,
                currentForm: formTemplate.sections[0].forms[0],
                currentStep: 1,
                currentSection: 1,
                forms: [],
                formsData: {},
                patient_data: {
                    completed: false
                },
                form_finished: false
            }))
        }

    }, [data]);

    const getCustomerCode = () => {
        let code = '';

        if (consultationState?.patient_data?.id) {
            code = (10000 + consultationState.patient_data.id).toString();
        }

        return code;
    }

    useEffect(() => {
        let tempIsNotFilled = false;
        if (consultationState.sections[consultationState.currentSection - 1]?.schema === 'patients') {

            let tempData:any = {
                id: consultationState.patient_data.id,
                first_name: consultationState.formsData.first_name,
                last_name: consultationState.formsData.last_name,
                mobile: consultationState.formsData.mobile,
                email: consultationState.formsData.email,
                occupation: consultationState.formsData.occupation,
                customer_code: getCustomerCode(),
                address: {
                    full: consultationState.formsData.address
                },
                email_verified_at: getDbFormat(new Date(Date.now())),
                mobile_verified_at: getDbFormat(new Date(Date.now())),
                emergency_contact: {
                    emergency_contact_full_name: consultationState.formsData.emergency_contact_full_name,
                    emergency_contact_phone: consultationState.formsData.emergency_contact_phone,
                    emergency_contact_email: consultationState.formsData.emergency_contact_email
                },
                birthday: consultationState.formsData.year + '-' + consultationState.formsData.month + '-' + consultationState.formsData.day,
                contact_method: consultationState.formsData.contact_method
            };

            patientIndexes.map((index:string) => {
                if (!tempData[index] || tempData[index].length === 0) {
                    tempIsNotFilled = true;
                }

                if ((!!tempData[index]) && (tempData[index].constructor === Object)) {
                    Object.keys(tempData[index]).map((key) => {
                        if ( !tempData[index][key] || tempData[index][key].length === 0) {
                            tempIsNotFilled = true;
                        }
                    });
                }
            });

            dispatch(setPatientData({...tempData, completed: !tempIsNotFilled}));
        }
    }, [consultationState.formsData]);

    const inputHandler = async (value:any, item:any) => {
        if (typeof value === "object") {
            dispatch(setFormData(value))
        } else {
            dispatch(setFormData({
                "id" : item.descriptor,
                "value" : value
            }))
        }
    }

    const hasNextStep = () => {
        if (consultationState.sections.length > consultationState.currentSection) {
            if (consultationState.sections[consultationState.currentSection - 1].forms.length > consultationState.currentStep) {
                return true;
            }
        } else if (consultationState.sections.length === consultationState.currentSection) {
            if (consultationState.sections[consultationState.currentSection - 1].forms.length > consultationState.currentStep) {
                return true;
            }
        }

        return false;
    }

    const hasNextSection = () => {
        if (consultationState.sections.length > consultationState.currentSection) {
            return !hasNextStep() && true;
        }

        return false;
    }

    const hasPrevStep = () => {
        if (consultationState.currentSection > 1) {
            return true;
        } else if (consultationState.currentSection === 1) {
            if (consultationState.currentStep > 1) {
                return true;
            }
        }

        return false;
    }

    const gotoNextStep = async () => {
        setIsReadyToSubmit(false);
        if (consultationState.currentForm && consultationState.currentForm.validator) {
            let formIsValid:boolean = consultationState.currentForm.validator.allValid();
            if (!formIsValid) {
                consultationState.currentForm.validator.showMessages();
                dispatch(inCreaseSubmitTries())
                setIsReadyToSubmit(true);
                return false;
            }
        }

        if (consultationState.currentSection && consultationState.sections[consultationState.currentSection - 1].schema === 'patients') {
            if (consultationState.patient_data.completed) {
                if (!consultationState.patient_data.id) {
                    let result = await addPatientData()

                    if (result?.data?.insert_patients_one?.id) {
                        dispatch(setPatientData({...consultationState.patient_data, id: result.data.insert_patients_one.id}))
                    } else {

                        setIsReadyToSubmit(true);
                        return false;
                    }
                } else {
                    let result = await updatePatientData()
                }
            }
        }

        dispatch(nextStep())
        setIsReadyToSubmit(true);
    }

    const finalize = async () => {
        let result = await addFormData();
        dispatch(setFormFinished())
    }

    return (
        <Grid
            templateAreas={`"header" "main" "footer"`}
            gridTemplateRows={'80px 0.99fr 30px'}
            gridTemplateColumns={'1fr'}
            h='100vh'
            gap='1'
        >
            <GridItem area={'header'}>
                <Container w='full' maxW="container.xl" h="100%">
                    <Flex h="100%">
                        <Button colorScheme={"blue"} variant={"link"} as={ReactRouterLink} to='/' display="flex">
                            <ArrowBackIcon fontSize="xl"></ArrowBackIcon>
                            <Text fontSize="md" mx="20px">Home</Text>
                        </Button>
                    </Flex>
                </Container>
            </GridItem>
            <GridItem p='2' area={'main'}>
                <Container w='full' maxW="450px" h="100%" justifyContent="center">

                    {consultationState.form_finished &&
                        <FinalPage></FinalPage>
                    }

                    {!consultationState.form_finished && consultationState.currentForm &&
                    <VStack>
                        <Heading fontSize="2xl">Request New Consultation</Heading>
                        <StepperProgressBar sections={consultationState.sections} activeSection={consultationState.currentSection}
                            activeStep={consultationState.currentStep} nextStep={nextStep} prevStep={prevStep}
                        ></StepperProgressBar>
                        <FormContainer nextStep={nextStep} prevStep={prevStep} inputItemHandler={inputHandler}
                            form={consultationState.currentForm} currentStep={consultationState.currentStep}
                            stepsCount={consultationState.sections[consultationState.currentSection - 1].forms.length}
                            state={consultationState.formsData} extendFormHandler={extendForms}
                            validator={consultationState.currentForm.validator}
                        ></FormContainer>
                        <Box h="30px"></Box>
                        {consultationState.currentForm && !consultationState.currentForm?.hideSubmitButton && hasNextStep() && !hasNextSection() &&
                            <ButtonBox item={
                                {
                                "title" : "Continue",
                                "descriptor" : "goto_contact_info",
                                "text" : "",
                                "type" : "button",
                                "action" : {
                                    "onClick" : () => {gotoNextStep()}
                                },
                                "required" : false,
                                "placeholder" : "",
                                "default_value" : "",
                                "component" : "",
                                "options" : {
                                    "type" : "submit",
                                    "rightIcon" : "ArrowForwardIcon",
                                    "props": {
                                        "isDisabled": !isReadyToSubmit,
                                        "w": "full",
                                        "variant": "btnMain"
                                    }
                                },
                                "values" : {},
                                "sub_items" : []
                            }
                            }></ButtonBox>
                        }

                        {consultationState.currentForm && !consultationState.currentForm?.hideSubmitButton && !hasNextStep() && hasNextSection() &&
                            <ButtonBox item={
                                {
                                    "title" : "Next Step",
                                    "descriptor" : "goto_contact_info",
                                    "text" : "",
                                    "type" : "button",
                                    "action" : {
                                        "onClick" : () => {gotoNextStep()}
                                    },
                                    "required" : false,
                                    "placeholder" : "",
                                    "default_value" : "",
                                    "component" : "",
                                    "options" : {
                                        "type" : "submit",
                                        "props": {
                                            "isDisabled": !isReadyToSubmit,
                                            "w": "full",
                                            "variant": "btnMain"
                                        }
                                    },
                                    "values" : {},
                                    "sub_items" : []
                                }
                            }></ButtonBox>
                        }

                        {consultationState.currentForm && !consultationState.currentForm?.hideSubmitButton && !hasNextStep() && !hasNextSection() &&
                            <ButtonBox item={
                                {
                                    "title" : "Submit",
                                    "descriptor" : "submit_form",
                                    "text" : "",
                                    "type" : "button",
                                    "action" : {
                                        "onClick" : () => {
                                            finalize()
                                        }
                                    },
                                    "required" : false,
                                    "placeholder" : "",
                                    "default_value" : "",
                                    "component" : "",
                                    "options" : {
                                        "type" : "submit",
                                        "props": {
                                            "w": "full",
                                            "variant": "btnMain"
                                        }
                                    },
                                    "values" : {},
                                    "sub_items" : []
                                }
                            }></ButtonBox>
                        }

                        {consultationState.currentForm && hasPrevStep() &&
                            <ButtonBox item={
                                {
                                    "title" : "Back",
                                    "descriptor" : "",
                                    "text" : "",
                                    "type" : "button",
                                    "action" : {
                                        "onClick" : () => {dispatch(prevStep())}
                                    },
                                    "required" : false,
                                    "placeholder" : "",
                                    "default_value" : "",
                                    "component" : "",
                                    "options" : {
                                        "type" : "submit",
                                        "props": {
                                            "w": "full",
                                            "variant": "link"
                                        }
                                    },
                                    "values" : {},
                                    "sub_items" : []
                                }
                            }></ButtonBox>
                        }
                    </VStack>
                    }
                </Container>
            </GridItem>
            <GridItem p='2' area={'footer'}>

            </GridItem>
        </Grid>
    )
}
