import {Link as ReactRouterLink, useParams} from "react-router-dom";
import {DOMElement, useEffect, useState} from "react";
import {MainTemplate} from "../../../../components/DashboardTemplate/MainTemplate";
import {
    Button,
    Card,
    CardBody,
    Container,
    Heading,
    HStack,
    Icon,
    Square,
    Text,
    Box,
    VStack,
    Circle, Grid, GridItem, Flex, Spinner, Divider, Image, CardHeader, SimpleGrid, Stack, Link
} from "@chakra-ui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import * as React from "react";
import ellipse6 from "../../../assets/images/ellipse-6.svg";
import { FaRegEnvelope } from "react-icons/fa";
import {PhoneIcon} from "@chakra-ui/icons";
import { FiPhone } from "react-icons/fi";
import { FiArrowUpRight } from "react-icons/fi";
import {gql, useLazyQuery} from "@apollo/client";
import {GET_PATIENT_DATA, GET_PATIENT_FORM_DATA} from "../queries";
import {getHRFormat} from "../../../../helpers/DateUtil"
import ModalBox from "../../../../components/ModalBox";
import TextBox from "../../../../components/FromsElements/TextBox";
import {setMobile} from "../../../../components/MobileAuthorizer/slice";
import { FaRegUser } from "react-icons/fa";
import {PageHeader} from "../../../../components/Template/PageHeader";
import {PageFooter} from "../../../../components/Template/PageFooter";
import {FormItem, SectionObject} from "../../../../interfaces/Interfaces";
import TextAreaBox from "../../../../components/FromsElements/TextAreaBox";
import emptyImage from "../../../../assets/images/appointment_empty.svg"
import Form from "./Layouts/Form";
import {Link as ChakraLink} from "@chakra-ui/layout/dist/link";

interface ConsultationFormProps {

}
const Index = (props:ConsultationFormProps) => {

    let params = useParams();
    const [patientInfoModalIsOpen, setPatientInfoModalIsOpen] = useState(false);
    const [getPatientFormData, {error, loading, data, called}] = useLazyQuery(GET_PATIENT_FORM_DATA, {
        variables: {
            id: params.fid,
        }
    });

    let menuDom:any = document.querySelector('#section_list_menu');
    let menuHolderDom:any = document.querySelector('#section_list_menu_holder');
    let offsetWidthOfMenu:number = 0;
    let offsetWidthOfMenuHolder:number = 0;

    const [activeSection, setActiveSection] = useState('public_information');
    const [isMovingMenu, setIsMovingMenu] = useState(false);
    const [lastX, setLastX] = useState(0);
    const [left, setLeft] = useState(0);
    const [overviewData, setOverviewData] = useState<Array<any>>([]);


    useEffect(() => {

        if (data?.form_data_by_pk) {
            let tempCards:Array<any> = [];
            let tempItems: Array<any> = [];
            let cardsIndex = 0;
            let currentDescriptor: string | null = null;

            if (data?.form_data_by_pk.form.template.sections.length > 0) {
                data?.form_data_by_pk.form.template.sections.map((section:any) => {
                    if (section.descriptor !== 'overview' && section.descriptor !== 'public_information') {
                        if (section.forms.length > 0) {
                            section.forms.map((form:any) => {
                                if (form.parent && form.parent !== null) {

                                } else if (currentDescriptor === null) {
                                    tempItems.push(form);
                                    currentDescriptor = form.descriptor;
                                } else if (currentDescriptor === form.descriptor) {
                                    tempItems.push(form);
                                } else {
                                    currentDescriptor = form.descriptor;
                                    if (tempItems.length > 0) {
                                        tempCards[cardsIndex] = {
                                            "title" : tempItems[0].title,
                                            "items" : tempItems
                                        };
                                        cardsIndex++;
                                        tempItems = [];
                                    }

                                    tempItems.push(form);
                                }
                            });
                        }
                        tempCards[cardsIndex] = {
                            "title" : tempItems[0].title,
                            "items" : tempItems,
                            "descriptor" : section.descriptor
                        };
                        cardsIndex++;
                        tempItems = [];
                    }
                });
            }

            setOverviewData(tempCards);
        }

    }, [data])

    useEffect(() => {
        menuDom = document.querySelector('#section_list_menu');
        menuHolderDom = document.querySelector('#section_list_menu_holder');
    }, []);

    useEffect(() => {
        if (params.fid) {
            getPatientFormData();
        }
    }, [params]);

    const modalCancelButton = () => {

        return (
            <Button variant="link" border="1px solid" borderColor="gray.400" w="full" mx="45px"
                    borderRadius="100px" h="48px" mb="30px"
                    onClick={() => {setPatientInfoModalIsOpen(false)}}
            >Close</Button>
        );
    }

    const moveMenu = () => {
        // TODO: use this function to move menu
    }

    return (
        <Container w="full" maxW="container.xl" mt="35px">
            <HStack>
                <Button as={ReactRouterLink} to={`/dashboard/patient/${params.pid}`} display="flex" bg="transparent" borderRadius="100px" h="48px"
                        w="120px" border="1px solid" borderColor="gray.300"
                >
                    <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
                    <Text fontSize="md" mx="10px">Back</Text>
                </Button>

                <Heading fontSize="30px" mx="15px">Consultation Form</Heading>
            </HStack>
            { data?.form_data_by_pk?.form?.template &&
                <Grid
                    mt="30px"
                    h='full'
                    w="full"
                    templateColumns='repeat(12, 1fr)'
                    gap={5}
                >
                    <GridItem colSpan={9}>
                        <Card w="full" mb="20px">
                            <CardBody  position="relative" px="30px">
                                {left < -30 &&
                                    <Flex position="absolute" left="0" alignItems="center" width="30px" h="full" top="0"
                                        cursor="pointer"
                                        onClick={() =>{
                                            setLeft(left + 25)
                                        }}
                                    >
                                        <ChevronLeftIcon fontSize="30px"></ChevronLeftIcon>
                                    </Flex>
                                }
                                <Box w="full" position="relative" overflowX="hidden" h="35px"
                                    id="section_list_menu_holder"
                                >
                                    <HStack id="section_list_menu"  position="absolute" left={left + 'px'} h="30px" gap="0"
                                        onMouseLeave={(e) => {
                                            e.preventDefault();
                                            setIsMovingMenu(false);
                                        }}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            setIsMovingMenu(true);
                                            menuDom = document.querySelector('#section_list_menu');
                                            menuHolderDom = document.querySelector('#section_list_menu_holder');
                                            setLastX(e.clientX);
                                        }}
                                        onMouseUp={(e) => {
                                            e.preventDefault();
                                            setIsMovingMenu(false);
                                        }}
                                        onMouseMove={(e) => {
                                            e.preventDefault();
                                            if (!isMovingMenu) {
                                                return false;
                                            }
                                            if (menuDom) {
                                                if (lastX > e.clientX) {
                                                    if (Math.abs(left - (lastX - e.clientX )) + menuHolderDom.offsetWidth < menuDom.offsetWidth) {
                                                        setLeft(left - (lastX - e.clientX ));
                                                    } else {
                                                        setLeft(-1 * (menuDom.offsetWidth - menuHolderDom.offsetWidth));
                                                    }
                                                } else if (lastX < e.clientX) {
                                                    if (left + (e.clientX - lastX) <= 0) {
                                                        setLeft(left + (e.clientX - lastX));
                                                    } else {
                                                        setLeft(0);
                                                    }
                                                }
                                            }
                                            setLastX(e.clientX)
                                        }}
                                    >
                                        {data?.form_data_by_pk?.form?.template?.sections.map((section:SectionObject) => {
                                            if (section.descriptor !== 'overview') {
                                                return (
                                                    <Text userSelect="none" overflow="hidden" whiteSpace="nowrap" key={section.descriptor}
                                                          textOverflow="ellipsis" fontSize="16px" px="15px"
                                                          fontWeight={section.descriptor === activeSection ? '700' : '400'}
                                                          textAlign="center" cursor="pointer"
                                                          onClick={() => {
                                                              setActiveSection(section.descriptor)
                                                          }}
                                                    >
                                                        {section.title}
                                                        {section.descriptor === activeSection &&
                                                            <Box mt="5px" mx="auto" w="50%" h="3px" bg="blue.200"></Box>
                                                        }
                                                    </Text>
                                                )
                                            }
                                        })}
                                    </HStack>
                                </Box>

                                {Math.abs(left) + menuHolderDom?.offsetWidth < menuDom?.offsetWidth  - 30 &&
                                    <Flex position="absolute" right="0" alignItems="center" width="30px"
                                        cursor="pointer" justifyContent="center" h="full" top="0"
                                        onClick={() =>{
                                            setLeft(left - 25)
                                        }}
                                    >
                                        <ChevronRightIcon fontSize="30px"></ChevronRightIcon>
                                    </Flex>
                                }
                            </CardBody>
                        </Card>
                        {activeSection === 'public_information' &&
                            <>
                                <Card w="full" px="15px" pt="10px" minW={{base: 'full', md: '550px'}}>
                                    <CardHeader>
                                        <HStack borderBottom="3px dashed" borderColor="gray.200" pb="15px">
                                            <Heading fontSize="24px">Personal Information</Heading>
                                        </HStack>
                                    </CardHeader>
                                    <CardBody>
                                        <SimpleGrid  w="full" maxW="full" minChildWidth="300px">
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Given Name</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.first_name}</Text>
                                            </VStack>
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Surname</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.last_name}</Text>
                                            </VStack>
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Birthday</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.birthday}</Text>
                                            </VStack>
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Occupation</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.occupation}</Text>
                                            </VStack>
                                        </SimpleGrid>
                                    </CardBody>
                                </Card>

                                <Card w="full" px="15px" pt="10px" minW={{base: 'full', md: '550px'}} mt="15px">
                                    <CardHeader>
                                        <HStack borderBottom="3px dashed" borderColor="gray.200" pb="15px">
                                            <Heading fontSize="24px">Contact Information</Heading>
                                        </HStack>
                                    </CardHeader>
                                    <CardBody>
                                        <SimpleGrid  w="full" maxW="full" minChildWidth="300px">
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Mobile Number</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.mobile}</Text>
                                            </VStack>
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Email</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.email}</Text>
                                            </VStack>
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Address</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.address.full}</Text>
                                            </VStack>
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Contact Method</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.contact_method}</Text>
                                            </VStack>
                                        </SimpleGrid>
                                    </CardBody>
                                </Card>

                                <Card w="full" px="15px" pt="10px" minW={{base: 'full', md: '550px'}} mt="15px">
                                    <CardHeader>
                                        <HStack borderBottom="3px dashed" borderColor="gray.200" pb="15px">
                                            <Heading fontSize="24px">Emergency Contact</Heading>
                                        </HStack>
                                    </CardHeader>
                                    <CardBody>
                                        <SimpleGrid  w="full" maxW="full" minChildWidth="300px">
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Emergency Contact Full Name</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.emergency_contact.emergency_contact_full_name}</Text>
                                            </VStack>
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Emergency Contact Phone</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.emergency_contact.emergency_contact_phone}</Text>
                                            </VStack>
                                            <VStack width={{base: "full", sm: "50%"}} alignItems="start" mb="15px">
                                                <Text fontSize="14px" fontWeight="400" color="gray.500">Emergency Contact Email</Text>
                                                <Text fontSize="15px" fontWeight="500">{data?.form_data_by_pk?.patient.emergency_contact.emergency_contact_email}</Text>
                                            </VStack>
                                        </SimpleGrid>
                                    </CardBody>
                                </Card>
                            </>
                        }

                        {
                            overviewData.length > 0 && overviewData.map((card, key) => {
                                return (
                                    <Card w="full"
                                        display={card.descriptor === activeSection ? 'flex' : 'none'} key={key + card.descriptor}
                                    >
                                        <CardHeader pb="0">
                                            <HStack borderBottom="3px dashed" borderColor="gray.200" pb="15px">
                                                <Heading size='md'>{card.title}</Heading>
                                            </HStack>
                                        </CardHeader>
                                        <CardBody py="0">
                                            {card.items.map((itemGroup:any) => {
                                                return (itemGroup.items.map((item:FormItem) => {
                                                    return (
                                                        <>
                                                            { item.type !== 'text' &&
                                                                <Form state={data?.form_data_by_pk?.data} key={item.descriptor} item={item}></Form>
                                                            }
                                                        </>
                                                    )
                                                }))
                                            })}
                                        </CardBody>
                                    </Card>
                                )
                            })
                        }

                    </GridItem>
                    <GridItem colSpan={3}>
                        <Card w="full" h="full">
                            <CardBody>
                                <Heading fontSize="24px" mb="15px"> Clinician Notes</Heading>
                                <TextAreaBox item={{
                                    descriptor: "note_text",
                                    title: "",
                                    values: [],
                                    placeholder: "Write your note",
                                    options: {
                                        max_character: 1500,
                                        props: {
                                            fontSize: "14px"
                                        }
                                    }
                                }}></TextAreaBox>
                                <Divider my="30px"></Divider>
                                <Heading fontSize="20px"> Clinician Notes</Heading>
                                <Box textAlign="center" mt="15px">
                                    <Image src={emptyImage} m="auto" w="54px"></Image>
                                    <Text fontSize="14px" fontWeight="400" color="gray">There are currently no data</Text>
                                </Box>
                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            }
        </Container>
    );
}


export default Index;