import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import ModalBox from "../../ModalBox";
import {Box, Heading, Text} from "@chakra-ui/react";
import TextBox from "../../FromsElements/TextBox";
import {FormItem} from "../../../interfaces/Interfaces";
import {setFormData} from "../../../pages/Consultation/slice";
import {setStep, setMobile} from "../slice";

interface InputMobileProps {
    state: any,
    onExitProcess?: any,
    onContinueProcess?: any,
    dataHandler?: any,
    validator?: any,
    sendErrorMessage?: string
}

const InputMobile = (props: InputMobileProps) => {
    const mobileAuthenticatorState = useSelector((state: RootState) => state.mobileAuthenticator)
    const dispatch = useDispatch();
    const validator = props.validator;
    const sendErrorMessage = props.sendErrorMessage;
    const mobileInput:FormItem = {
        title: "Mobile Number",
        descriptor: "mobile",
        placeholder: "+61 4XX XXX XXX",
        values: {},
        validation_rule: "required"
    };
    const [isModalOpen, setIsModalOpen] = useState(true);

    return (
        <Box>
            <Heading size="md" textAlign="center">Mobile Number Authorization</Heading>
            <Text textAlign="center" p="15px" mx="15px">
                Please enter your mobile number to get verification code.
            </Text>
            <TextBox item={mobileInput} state={mobileAuthenticatorState} validator={validator}
                inputItemHandler={(value:string,item:any) => {
                    dispatch(setMobile(value));
                }}
            ></TextBox>
            {sendErrorMessage && sendErrorMessage.length > 0 &&
                <Text color="red.500">{sendErrorMessage}</Text>
            }
        </Box>
    );
}

export default InputMobile;