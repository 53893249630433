import * as React from "react"
import {
    Box,
    Heading,
    Flex,
    Image,
    Text,
    GridItem,
    Grid,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Card,
    CardBody, Stack
} from "@chakra-ui/react"
import {Link as ReactRouterLink, useParams} from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import {PageHeader} from "../../components/Template/PageHeader";
import {MainTemplate} from "../../components/Template/MainTemplate";
import {ChevronRightIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
// import testData from "../../TestData/patientResources.json"
import {SourceLayout} from "./layouts/SourceLayout";
import {BookConsultationForm} from "../../components/BookConsultationForm";
import {useQuery} from "@apollo/client";
import {GET_PATIENT_RESOURCES} from "./queries";

export const ShowResource = () => {

    let params = useParams();

    // const [data, setData] = useState<Array<any>>([]);
    const [resource, setResource] = useState<any|null>(null);

    const {error, loading, data} = useQuery(GET_PATIENT_RESOURCES);


    useEffect(() => {
        if (data && data.patient_resources) {
            data.patient_resources.map((source:any) => {
                if (source.descriptor === params.descriptor) {
                    setResource(source);
                }
            });;
        }
    }, [data, loading]);

    return (
        <MainTemplate>
            <Container maxW="container.xl" mt="20px" mb="40px">
                <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.300' fontSize="xl"/>}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href='/patients/resources' fontSize="14px" fontWeight="500">Patient Resources</BreadcrumbLink>
                    </BreadcrumbItem>
                    {resource &&
                        <BreadcrumbItem>
                            <BreadcrumbLink href={`/patients/resources/${resource.descriptor}`} fontSize="14px" fontWeight="500">{resource.title}</BreadcrumbLink>
                        </BreadcrumbItem>
                    }
                </Breadcrumb>
            </Container>

            {resource &&
                <>
                    <SourceLayout data={{title: resource.title, image: resource.image, description: resource.description}}></SourceLayout>

                    { resource.data && resource.data.length > 0 && resource.data.map((section:any) => {
                        return (
                            <Container maxW="container.xl">
                                {section.component && section.component === 'BookConsultationForm' &&
                                    <BookConsultationForm data={section}></BookConsultationForm>
                                }

                                {(!section.component || section.component === '') &&
                                    <SourceLayout data={section} layoutType={section.layout}></SourceLayout>
                                }
                            </Container>
                        );
                    })}
                </>
            }

        </MainTemplate>
    );

}