import * as React from "react"
import {
    Box, Heading, Flex, Image, Text
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import tempIcon from "../../assets/images/doctor.png";
import {MainTemplate} from "../../components/DashboardTemplate/MainTemplate";
import { Outlet } from "react-router-dom";
import {useEffect, useState} from "react";
import {Login} from "./Login";
import useAuth from "../../hooks/useAuth";

export const Dashboard = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<string|null>(null);
    const [loading, setLoading] = useState<boolean>(true)

    const {user_id} = useAuth();

    useEffect(() => {
        if (user_id) {
            setIsLoggedIn(user_id);
        }

        setLoading(false);
    }, []);

    return(
        <>
            {!loading && isLoggedIn &&
                <MainTemplate>
                    <Outlet></Outlet>
                </MainTemplate>
            }

            {!loading && !isLoggedIn &&
                <Login></Login>
            }

            {loading &&
                <Flex position="fixed" top="0" right="0" left="0" bottom="0" bg="white" justifyContent="center" alignItems="center">
                    Loading
                </Flex>
            }
        </>
    )
}
