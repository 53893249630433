import {ColorModeScript, Text} from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import {store} from './store/Store'
import {Provider} from 'react-redux'
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink } from '@apollo/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { setContext } from '@apollo/client/link/context';
import {
    ChakraProvider,
} from "@chakra-ui/react"

import { theme } from './chakra-ui/theme';
import {Fonts} from "./chakra-ui/fonts";


import {Home} from "./pages/Home";
import {Consultation} from "./pages/Consultation";
import {PatientResources} from "./pages/PatientResources";
import {ShowResource} from "./pages/PatientResources/ShowResource";
import {Login} from "./pages/Dashboard/Login";
import {Dashboard} from "./pages/Dashboard";
import {Home as DashboardHome} from "./pages/Dashboard/Home";
import Patient from "./pages/Dashboard/Patient";
import ConsultationForm from "./pages/Dashboard/Patient/ConsultationForm";
import Session from "./pages/Dashboard/Patient/Session/index";
import FitzSkinForm from "./pages/Dashboard/Patient/FitzSkinForm";
import PatientList from "./pages/Dashboard/PatientList";

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

const router = createBrowserRouter([
    {
        path: "/",
        element: <PatientResources/>,
    },
    {
        path: "/consultation/request",
        element: <Consultation/>,
    },
    {
        path: "/patients/resources",
        element: <PatientResources/>,
    },
    {
        path: "/patients/resources/:descriptor",
        element: <ShowResource/>,
    },
    {
        path: "/dashboard/login",
        element: <Login/>,
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
            {
                path: "/dashboard/",
                element: <DashboardHome />,
            },
            {
                path: "/dashboard/patient/list/:p?/:k?",
                element: <PatientList />,
            },
            {
                path: "/dashboard/patient/:id",
                element: <Patient />,
            },
            {
                path: "/dashboard/patient/:pid/form/:fid",
                element: <ConsultationForm />,
            },
            {
                path: "/dashboard/patient/:pid/skin/:fdid?",
                element: <FitzSkinForm />,
            },
            {
                path: "/dashboard/patient/:pid/treatment/:tid/session/:sid",
                element: <Session />,
            },
        ],
    },
]);

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}/v1/graphql`,
});

const authLink = setContext((_, { headers }) => {

    const token = 'token';
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
});

root.render(
    <React.StrictMode>
        <ColorModeScript/>
        <Provider store={store}>
            <ApolloProvider client={client}>
                <ChakraProvider theme={theme}>
                    <Fonts />
                    <RouterProvider router={router} />
                </ChakraProvider>
            </ApolloProvider>
        </Provider>
    </React.StrictMode>,
)

