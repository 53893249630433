import ModalBox from "../../../../../components/ModalBox";
import {FiFilePlus} from "react-icons/fi";
import {Box, Button, Heading, Text, Alert, AlertIcon, VStack, Circle, Flex, HStack, Input} from "@chakra-ui/react";
import SelectBox from "../../../../../components/FromsElements/SelectBox";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    ADD_TREATMENT_USER_SESSIONS,
    UPDATE_TREATMENT_USER_SESSION_BY_PK
} from "../../queries";
import useAuth from "../../../../../hooks/useAuth";
import {useParams} from "react-router-dom";
import useCustomToast from "../../../../../hooks/useCustomToast";
import TextBox from "../../../../../components/FromsElements/TextBox";
import DatePickerBox from "../../../../../components/FromsElements/DatePickerBox";
import TimePickerBox from "../../../../../components/FromsElements/TimePickerBox";
import {addMinutesToATime} from "../../../../../helpers/DateUtil";
interface AddSessionModalProps {
    isOpen: boolean,
    closeAction: any,
    saveAction: any,
    defaultState: {
        id: number|null,
        start_time: String|null,
        end_time: String|null,
        duration: number|null,
        date: String|null,
        status: String|null,
        treatment_user_id: String|null,
        patient_id: String|null,
        user_id: String|null
    }
}
const AddSessionModal = (props:AddSessionModalProps) => {
    const {user_id} = useAuth();
    let params = useParams();
    const [addSessionModalIsOpen, setAddSessionModalIsOpen] = useState(false);
    const [addTreatmentUserSessions] = useMutation(ADD_TREATMENT_USER_SESSIONS)
    const toast = useCustomToast();
    const [stateData, setStateData] = useState<any>(props.defaultState);
    const [updateTreatmentUserSessionByPk] = useMutation(UPDATE_TREATMENT_USER_SESSION_BY_PK)
    const [btnLoading, setBtnLoading] = useState(false);

    let durationValues:any = {
        0: {
            title: 'Select Duration',
            value: 0
        }
    };
    let step = 15;
    for (let i = 1; i <= 20; i++) {
        durationValues[i * step] = {
            title: Math.floor((i * step) / 60).toString().padStart(2, '0') + ':' + (((i * step / 60) - Math.floor((i * step) / 60)) * 60).toString().padStart(2, '0'),
            value: i * step
        }
    }

    useEffect(() => {
        if (props.defaultState?.patient_id) {
            setStateData(props.defaultState);
        }
    }, [props.defaultState]);

    const addSessionModalCancelButton = () => {

        return (
            <Button variant="btnNoraml" border="1px solid" borderColor="gray.400" w="full"
                borderRadius="100px" h="48px" mb="30px" ms="45px"
                onClick={() => {
                    setStateData(props.defaultState);

                    props.closeAction()
                }}
            >Cancel</Button>
        );
    }

    const addSessionModalActionButton = () => {

        return (
            <Button variant="btnMain" border="1px solid" borderColor="gray.400" w="full" isDisabled={btnLoading}
                borderRadius="100px" h="48px" mb="30px" me="45px"
                onClick={async () => {
                    setBtnLoading(true);
                    if (stateData.id) {
                        let result = await updateTreatmentUserSessionByPk({
                            variables: {
                                id: stateData.id,
                                object: {
                                    start_time: stateData.start_time,
                                    end_time: stateData.end_time,
                                    duration: stateData.duration,
                                    date: stateData.date,
                                    status: stateData.status
                                }
                            }
                        });

                        if (result?.data?.update_treatment_user_sessions_by_pk?.id) {
                            toast.success('Session has been successfully updated.');
                            setStateData({
                                id: null,
                                start_time: null,
                                end_time: null,
                                duration: null,
                                date: null,
                                status: null,
                                treatment_user_id: null,
                                patient_id: null,
                                user_id: null
                            });
                            props.saveAction();
                        } else {

                        }
                    } else {
                        let result = await addTreatmentUserSessions({
                            variables: {
                                objects: [{
                                    patient_id: stateData.patient_id,
                                    status: stateData.status,
                                    treatment_user_id: stateData.treatment_user_id,
                                    user_id: user_id,
                                    start_time: stateData.start_time,
                                    end_time: stateData.end_time,
                                    duration: stateData.duration,
                                    date: stateData.date
                                }]
                            }
                        });

                        if (result.data.insert_treatment_user_sessions.affected_rows) {
                            toast.success('New Session has been successfully added.');
                            setStateData({
                                id: null,
                                start_time: null,
                                end_time: null,
                                date: null,
                                status: null,
                                duration: null,
                                treatment_user_id: null,
                                patient_id: null,
                                user_id: null
                            });
                            props.saveAction(result.data.insert_treatment_user_sessions.returning[0].id);
                        } else {
                            // Show Error
                        }
                    }
                    props.closeAction();
                    setBtnLoading(false);
                }}
            >{stateData.id ? 'Save' : 'Create'}</Button>
        );
    }

    return (
        <ModalBox isOpen={props.isOpen} onClose={() => {setStateData(props.defaultState); props.closeAction()}}
            size="md" icon={FiFilePlus} iconsize="26px"
            cancelButton={addSessionModalCancelButton()}
            actionButton={addSessionModalActionButton()}
        >
            <Box>
                <Heading size="md" textAlign="center">Create new session</Heading>

                <VStack w="full" mt="30px">
                    <HStack mt="15px" w="full">
                        <TimePickerBox
                            w="50%" item={{
                                descriptor: "start_time",
                                title: "Start Time",
                                values: {},
                            }}
                            state={stateData}
                            inputItemHandler={(value:string|null,item:any) => {
                                let endTime:any = null;
                                if (value !== '0' && value !== null) {
                                    if (stateData.duration) {
                                        endTime = addMinutesToATime(value, stateData.duration);
                                    }
                                } else {
                                    value = null;
                                }

                                setStateData({...stateData, end_time: endTime, start_time: value})
                            }}
                        >
                        </TimePickerBox>

                        <SelectBox w="50%" item={{
                            descriptor: "duration",
                            title: "Duration",
                            values: durationValues
                        }} state={stateData}
                        inputItemHandler={(value:string|null,item:any) => {
                            let endTime:any = null;

                            if (value !== '0') {

                                if (stateData.start_time && value) {
                                    endTime = addMinutesToATime(stateData.start_time, parseInt(value));
                                }
                            } else {
                                value = null;
                            }

                            setStateData({...stateData, end_time: endTime, duration: value})
                        }}
                        ></SelectBox>
                    </HStack>
                    <HStack mt="15px" w="full">
                        <DatePickerBox w="50%" item={{
                            descriptor: "date",
                            title: "Date",
                            values: {},
                        }}
                        state={stateData}
                        inputItemHandler={(value:string,item:any) => {
                            setStateData({...stateData, date: value})
                        }}
                        ></DatePickerBox>
                        {stateData.id &&
                        <SelectBox w="50%" item={
                            {
                                descriptor: 'status',
                                title: 'Status',
                                values: {
                                    null: {
                                        title: "Select Status",
                                        value: null
                                    },
                                    PENDING: {
                                        title: "Pending",
                                        value: "PENDING"
                                    },
                                    CANCELED: {
                                        title: "Canceled",
                                        value: "CANCELED"
                                    },
                                    DONE: {
                                        title: "Done",
                                        value: "DONE"
                                    }
                                }
                            }}
                            inputItemHandler={(value:string,item:any) => {
                               setStateData({...stateData, status: value})
                            }}
                            state={stateData}
                        ></SelectBox>
                        }
                    </HStack>
                </VStack>

            </Box>
        </ModalBox>
    );
}

export default AddSessionModal;