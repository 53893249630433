import ModalBox from "../../../../../components/ModalBox";
import {FiFilePlus} from "react-icons/fi";
import {Box, Button, Heading, Text, Alert, AlertIcon, VStack, Circle, Flex, HStack, Spinner} from "@chakra-ui/react";
import SelectBox from "../../../../../components/FromsElements/SelectBox";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {ADD_TREATMENT_USER, ADD_TREATMENT_USER_SESSIONS, GET_TREATMENTS} from "../../queries";
import useAuth from "../../../../../hooks/useAuth";
import {useParams} from "react-router-dom";
import useCustomToast from "../../../../../hooks/useCustomToast";
import axiosInstance from "../../../../../helpers/axiosInstance";
import {getPatientImageBasePath} from "../../../../../helpers/S3Options";
interface AddTreatmentModalProps {
    isOpen: boolean,
    patient: any,
    closeAction: any
}
const AddTreatmentModal = (props:AddTreatmentModalProps) => {
    const {user_id} = useAuth();
    let params = useParams();
    const [addTreatmentModalIsOpen, setAddTreatmentModalIsOpen] = useState(false);
    const [dataState, setDataState] = useState<any>({
        treatmentId: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const [treatments, setTreatments] = useState<Array<any>>([]);
    const [selectedTreatment, setSelectedTreatment] = useState<any>(null);
    const [InsertTreatmentUser] = useMutation(ADD_TREATMENT_USER)
    const [addTreatmentUserSessions] = useMutation(ADD_TREATMENT_USER_SESSIONS)
    const toast = useCustomToast();
    const addTreatmentUser = async () => {
        let result = await InsertTreatmentUser({
            variables: {
                userId: user_id,
                patientId: params.id,
                treatmentId: dataState.treatmentId,
                description: '',
                data: {},
                title: '',
                sessionNumber: selectedTreatment?.data?.session_required
            }
        });

        if (result?.data?.insert_treatment_users_one?.id) {
            let objects:any = [];

            for (let i = 1; i <= selectedTreatment.data.session_required; i++) {
                objects.push({
                    patient_id: params.id,
                    status: "PENDING",
                    treatment_user_id: result.data.insert_treatment_users_one.id,
                    user_id: user_id
                });
            }

            let resultSession = await addTreatmentUserSessions({
                variables: {
                    objects: objects
                }
            });

            if (resultSession?.data?.insert_treatment_user_sessions.affected_rows) {
                // CREATE FOLDERS
                let basePath = getPatientImageBasePath(props.patient, selectedTreatment?.descriptor, result?.data?.insert_treatment_users_one?.id);

                let folders = [];

                for (let i = 1; i <= resultSession.data.insert_treatment_user_sessions.affected_rows; i++) {
                    folders.push(basePath + 'session_' + i + '_' + resultSession.data.insert_treatment_user_sessions.returning[i - 1].id + '/');
                    folders.push(basePath + 'session_' + i + '_' + resultSession.data.insert_treatment_user_sessions.returning[i - 1].id + '/after/');
                    folders.push(basePath + 'session_' + i + '_' + resultSession.data.insert_treatment_user_sessions.returning[i - 1].id + '/before/');
                }

                let createFolderResult = await axiosInstance().post('/storage/create_folder', {
                    keys: folders
                });
            }

            return true;
        }

        return false;
    }
    const addTreatmentModalCancelButton = () => {

        return (
            <Button variant="btnNoraml" border="1px solid" borderColor="gray.400" w="full"
                    borderRadius="100px" h="48px" mb="30px" ms="45px"
                    onClick={() => {
                        setSelectedTreatment(null);
                        setDataState({
                            treatmentId: null
                        });
                        props.closeAction()
                    }}
            >Cancel</Button>
        );
    }

    const addTreatmentModalActionButton = () => {

        return (
            <Button variant="btnMain" border="1px solid" borderColor="gray.400" w="full" isDisabled={!selectedTreatment || isLoading}
                    borderRadius="100px" h="48px" mb="30px" me="45px"
                    onClick={async () => {
                        setIsLoading(true);
                        let result = await addTreatmentUser();
                        if (result) {
                            toast.success('New treatment has been successfully assigned.');
                            setSelectedTreatment(null);
                            setDataState({
                                treatmentId: null
                            });
                            props.closeAction();
                        }
                        setIsLoading(false);
                    }}
            >
                Create
                {isLoading &&
                    <><Spinner /></>
                }
            </Button>
        );
    }

    const [getTreatments, {error, loading, data, called}] = useLazyQuery(GET_TREATMENTS, {
        variables: {

        }
    });

    useEffect(() => {
        getTreatments();
    }, []);

    useEffect(() => {
        if (data && data.treatments) {
            let temp:Array<any> = [
                {
                    title: "Choose an option",
                    value: 0
                }
            ];
            data.treatments.map((treatment:any) => {
                temp.push({
                    title: treatment.title,
                    value: treatment.id
                });
            });
            setTreatments(temp);
        }
    }, [data]);

    return (
        <ModalBox isOpen={props.isOpen} onClose={() => {props.closeAction()}}
                  size="xl" icon={FiFilePlus} iconsize="26px"
                  cancelButton={addTreatmentModalCancelButton()}
                  actionButton={addTreatmentModalActionButton()}
        >
            <Box px="45px">
                <Heading size="md" textAlign="center">Assign Treatment Plan</Heading>
                <Text textAlign="center" my="20px">To create a new treatment plan, select a treatment.</Text>

                <SelectBox item={{
                    title: "",
                    descriptor: "",
                    values: treatments
                }} inputItemHandler={(value:string,item:any) => {
                    if (parseInt(value) > 0) {
                        setDataState({treatmentId: parseInt(value)})
                        data.treatments.map((t:any) => {
                            if (t.id === parseInt(value)) {
                                setSelectedTreatment(t);
                            }
                        });
                    } else {
                        setDataState({treatmentId: null});
                        setSelectedTreatment(null);
                    }
                }} state={dataState}></SelectBox>

                { dataState.treatmentId &&
                    <HStack bg="#F0F6FF" p="12px" borderRadius="15px" mt="15px">
                        <Circle size="5" color="white" bg="#0056D9" fontSize="12px" fontWeight="700">
                            !
                        </Circle>
                        <Text fontSize="14px" fontWeight="400">
                            {selectedTreatment?.data?.session_required} session included.
                        </Text>
                    </HStack>
                }
            </Box>
        </ModalBox>
    );
}

export default AddTreatmentModal;