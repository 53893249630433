import {Fragment} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Box, FormControl, FormLabel, Input, Checkbox as ChakraCheckbox, Stack, CheckboxProps} from "@chakra-ui/react";
import {useDispatch} from "react-redux";

interface checkBoxProps extends FormItemProps, CheckboxProps {

}

const CheckBox = (props: checkBoxProps) => {

    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    return (
        <FormControl>
            {item.title.length > 0 &&
                <FormLabel>{item.title}</FormLabel>
            }

            <Stack spacing={5} direction='row'>
                <ChakraCheckbox colorScheme='blue' size='lg'
                    isChecked={state[item.descriptor] && state[item.descriptor] !== 'unchecked' ? state[item.descriptor] : false}
                    key={item.descriptor} onChange={(e) => {
                        if (inputItemHandler) {
                            inputItemHandler(!state[item.descriptor], item)
                        }
                    }}
                    isInvalid={IsInvalidMessage ? IsInvalidMessage : false}
                >
                    {item.text && item.text.length > 0 &&
                        <Box fontSize="16px" dangerouslySetInnerHTML={{__html: item.text}}></Box>
                    }
                </ChakraCheckbox>
            </Stack>
            {!item?.hide_validation_error && IsInvalidMessage}
        </FormControl>
    );
}

export default CheckBox;
