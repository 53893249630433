import React from 'react';
import {FormButtonProps, FormItemProps} from "../../../interfaces/Interfaces";
import {Button, Icon} from "@chakra-ui/react";
import { forwardRef, ButtonProps } from '@chakra-ui/react'
import {ArrowForwardIcon, ArrowBackIcon} from "@chakra-ui/icons";

interface ButtonBoxProps extends FormButtonProps, FormItemProps {

}

const ButtonBox = (props: ButtonBoxProps) => {
    let item = props.item;

    let customProps = item.options?.props;
    let action = item.action;

    return (
        <Button {...customProps} {...action} borderRadius="25px" h="45px">
            {item?.options?.leftIcon && item.options.leftIcon === 'ArrowBackIcon' &&
                <ArrowBackIcon me="15px"></ArrowBackIcon>
            }
            {item.title}
            {item?.options?.rightIcon && item.options.rightIcon === 'ArrowForwardIcon' &&
                <ArrowForwardIcon ms="15px"></ArrowForwardIcon>
            }

        </Button>
    );
}

export default ButtonBox;
