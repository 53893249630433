import React, {useEffect, useState} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Box, FormControl, FormLabel, HStack, Input, Text} from "@chakra-ui/react";
import FormElementsContainer from "../FormElementsContainer";
const FormGroup = (props: FormItemProps) => {

    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    // const [IsInvalidMessage, setIsInvalidMessage] = useState<any>(null);
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    useEffect(() => {
        if (item.sub_items && item.sub_items.length > 0) {
            let values:Array<string> = [];
            item.sub_items.map((subItem) => {
                if (state[subItem.descriptor] && state[subItem.descriptor].length > 0) {
                    values.push(state[subItem.descriptor]);
                }
            });

            if (values.length === item.sub_items.length) {
                inputItemHandler(values.join('-'), item);
            }
        }
    }, [state]);

    return (
        <FormControl>
            {item.title.length > 0 &&
                <FormLabel>
                    {item.required &&
                        <Text as="span" color="red.500" fontSize="14px" me="5px">*</Text>
                    }
                    {item.title}
                </FormLabel>
            }
            {item.sub_items && item.sub_items.length > 0 &&
                <HStack>
                    <FormElementsContainer validator={validator} state={state} inputItemHandler={inputItemHandler} items={item.sub_items}></FormElementsContainer>
                </HStack>
            }
            {IsInvalidMessage}
        </FormControl>
    );
}

export default FormGroup;
