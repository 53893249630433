import { gql } from "@apollo/client";
import client from "@apollo/client";

export const GET_PATIENTS = gql`
    query searchPatientByNameByCode($keyword: String!){
        patients(limit: 10, offset: 0, where: {
            center_id: {_eq: ${process.env.REACT_APP_CENTER_ID}},
            _and: {
                _or: [
                    {first_name: {_ilike: $keyword}},
                    {last_name: {_ilike: $keyword}},
                    {customer_code: {_ilike: $keyword}}
                ]
            }
        }
        )
        {
            id
            customer_code
            first_name
            last_name
        }
    }
`;

