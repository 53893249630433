import React, {useEffect, useState} from 'react';
import {FormItemProps} from "../../../interfaces/Interfaces";
import {Text, FormControl, FormLabel, Input, Select, InputProps, HStack} from "@chakra-ui/react";
import {useDispatch} from "react-redux";
import {InfoOutlineIcon} from "@chakra-ui/icons";
interface SelectBoxInterFace extends FormItemProps, InputProps {

}
const SelectBox = (props: SelectBoxInterFace) => {
    const dispatch = useDispatch()
    let item = props.item;
    let inputItemHandler = props.inputItemHandler;
    let state = props.state;
    let validator = props.validator;
    let IsInvalidMessage = (validator && item.validation_rule)? validator.message(item.title, state[item.descriptor], item.validation_rule) : null;

    let recommendationData = props.recommendationData;
    const [recommendedValue, setrecommendedValue] = useState<any>(null);
    useEffect(() => {
        if (item && item?.getRecommendedValue) {
            let func = new Function('recommendations,data', item.getRecommendedValue);
            setrecommendedValue(func(item.recommendations, recommendationData))
        }
    }, [item])


    return (
        <FormControl w={props.w ?? ''}>
            {item.title.length > 0 &&
                <FormLabel flexDirection="column">
                    {item.required &&
                        <Text as="span" color="red.500" fontSize="14px" me="5px">*</Text>
                    }
                    <Text as="span">{item.title}</Text>
                </FormLabel>
            }

            <Select key={item.descriptor} fontSize="14px" fontWeight="400"
                value={state[item.descriptor] ? state[item.descriptor] : ''}
                {...item?.options?.props}
                placeholder={item.placeholder && item.placeholder.length > 0 ? item.placeholder : ""}
                onChange={(e) => {
                    if (inputItemHandler) {
                        inputItemHandler(e.target.value, item);
                    }
                }}
                isInvalid={IsInvalidMessage ? IsInvalidMessage : false}
            >
                {item.values && Object.keys(item.values).map((optionKey) => {
                    return (
                        item.values &&
                        <option key={item.values[optionKey].value} value={item.values[optionKey].value}>{item.values[optionKey]?.title ? item.values[optionKey]?.title : item.values[optionKey]?.value}</option>
                    );
                })}
            </Select>
            {item.recommendations && recommendedValue !== null &&
                <HStack my="10px">
                    <InfoOutlineIcon color="#757575"></InfoOutlineIcon>
                    <Text flex="1" fontSize="14px" fontWeight="400" color="#757575">Recommended: <Text color="#2E2E2E" as="span">{recommendedValue}</Text></Text>
                    <Text fontSize="14px" fontWeight="400" color="#0065FF" cursor="pointer"
                          onClick={() => {
                              inputItemHandler(recommendedValue, item);
                          }}
                    >Apply Recommended</Text>
                </HStack>
            }
            {!item?.hide_validation_error && IsInvalidMessage}
        </FormControl>
    );
}

export default SelectBox;
