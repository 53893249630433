import {Link as ReactRouterLink, useLocation, useParams} from "react-router-dom";
import {useApolloClient, useMutation} from "@apollo/client";
import {useEffect, useState} from "react";
import {MainTemplate} from "../../../components/DashboardTemplate/MainTemplate";
import axiosInstance from "../../../helpers/axiosInstance";
import {
    Button,
    Card,
    CardBody,
    Container,
    Heading,
    HStack,
    Icon,
    Square,
    Text,
    Box,
    VStack,
    Circle,
    SimpleGrid,
    Image,
    Stack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    IconButton
} from "@chakra-ui/react";
import { ImSpinner } from "react-icons/im";

import {
    ChevronLeftIcon,
    CheckIcon,
    ArrowForwardIcon,
    SmallCloseIcon,
    DeleteIcon,
    SpinnerIcon,
    RepeatClockIcon,
    TimeIcon, EditIcon
} from "@chakra-ui/icons";
import * as React from "react";
import ellipse6 from "../../../assets/images/ellipse-6.svg";
import { FaRegEnvelope } from "react-icons/fa";
import {PhoneIcon} from "@chakra-ui/icons";
import { FiPhone } from "react-icons/fi";
import { FiArrowUpRight, FiArrowRight } from "react-icons/fi";
import {gql, useLazyQuery} from "@apollo/client";
import {
    CHANGE_TREATMENT_USER_STATUS,
    GET_PATIENT_DATA
} from "./queries";
import {getDayName, getHRFormat} from "../../../helpers/DateUtil"
import ModalBox from "../../../components/ModalBox";
import TextBox from "../../../components/FromsElements/TextBox";
import {setMobile} from "../../../components/MobileAuthorizer/slice";
import { FaRegUser } from "react-icons/fa";
import { FiFilePlus } from "react-icons/fi";
import SelectBox from "../../../components/FromsElements/SelectBox";
import AddTreatmentModal from "./Treatment/modals/AddTreatmentModal";
import {Link as ChakraLink} from "@chakra-ui/layout/dist/link";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import Confirmation from "../../../components/Confirmation";
import AddSessionModal from "./Treatment/modals/AddSessionModal";
import treatment from "./Treatment";
import useAuth from "../../../hooks/useAuth";
import {getPatientImageBasePath} from "../../../helpers/S3Options";


interface PatientProps {

}
const Patient = (props:PatientProps) => {
    const borderBg = 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'10\' ry=\'10\' stroke=\'%23C9C9C9FF\' stroke-width=\'2\' stroke-dasharray=\'6%2c 10\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e")';
    const client = useApolloClient();
    let params = useParams();
    const {user_id} = useAuth();
    let {state} = useLocation();
    const [patientInfoModalIsOpen, setPatientInfoModalIsOpen] = useState(false);
    const [addTreatmentModalIsOpen, setAddTreatmentModalIsOpen] = useState(false);
    const [selectedTreatment, setSelectedTreatment] = useState<any>(null);
    const [getPatientData, {error, loading, data, called}] = useLazyQuery(GET_PATIENT_DATA, {
        fetchPolicy: "no-cache",
        variables: {
            id: params.id
        }
    });
    const [currentTreatmentModalIsOpen, setCurrentTreatmentModalIsOpen] = useState(false)
    const [cancelTreatmentModalIsOpen, setCancelTreatmentModalIsOpen] = useState(false)
    const [completeTreatmentModalIsOpen, setCompleteTreatmentModalIsOpen] = useState(false)
    const [deleteTreatmentModalIsOpen, setDeleteTreatmentModalIsOpen] = useState(false)
    const [addSessionModalIsOpen, setAddSessionModalIsOpen] = useState(false)

    const [consultationFormData, setConsultationFormData] = useState<any>(null);
    const [fitzSkinTypeFormData, setFitzSkinTypeFormData] = useState<any>(null);

    const defaultSessionData = {
        id: null,
        start_time: null,
        end_time: null,
        duration: null,
        date: null,
        status: null,
        treatment_user_id: null,
        patient_id: null,
        user_id: null
    };
    const [selectedSessionData, setSelectedSessionData] = useState<any>(defaultSessionData);

    const [updateTreatmentUserStatus] = useMutation(CHANGE_TREATMENT_USER_STATUS)

    useEffect(() => {
        if (params.id) {
            getPatientData();
        }
    }, [params]);

    useEffect(() => {
        if (!selectedTreatment && data?.patients_by_pk?.treatments && data?.patients_by_pk?.treatments?.length > 0) {
            if (selectedSessionData && selectedSessionData.treatment_user_id) {
                data.patients_by_pk.treatments.map((t:any) => {
                    if (t.id == selectedSessionData.treatment_user_id) {
                        setSelectedTreatment(t);
                    }
                });
            } else {
                setSelectedTreatment(data.patients_by_pk.treatments[0]);
            }
        }

        if (data?.patients_by_pk?.patient_forms_data && data?.patients_by_pk?.patient_forms_data.length > 0) {
            data?.patients_by_pk?.patient_forms_data.map((formData:any) => {
                if (formData.form.descriptor === 'CONSULTATION_FORM') {
                    setConsultationFormData(formData);
                } else if (formData.form.descriptor === 'FITZ_SKIN_TYPE_FORM') {
                    setFitzSkinTypeFormData(formData);
                }
            });
        }

    }, [data]);

    useEffect(() => {
        if (selectedTreatment?.id) {
            let tempData:any = JSON.parse(JSON.stringify(defaultSessionData));
            tempData.user_id = user_id;
            tempData.treatment_user_id = selectedTreatment.id;
            tempData.patient_id = params?.id ?? null;

            setSelectedSessionData(tempData);
        }

    }, [selectedTreatment]);

    const patientInfoModalCancelButton = () => {

        return (
            <Button variant="link" border="1px solid" borderColor="gray.400" w="full" mx="45px"
                borderRadius="100px" h="48px" mb="30px"
                onClick={() => {setPatientInfoModalIsOpen(false)}}
            >Close</Button>
        );
    }

    const getNumberOfDoneSessions = (sessions:Array<any>) => {
        let count:number = 0;
        if (sessions.length) {
            sessions.map((session:any) => {
                if (session.status === 'DONE') {
                    count++;
                }
            });
        }

        return count;
    }

    return (
        <Container w="full" maxW="container.xl" mt="35px">
            <HStack>
                <Button as={ReactRouterLink} to={state && state.back_url ? state.back_url : '/dashboard/'} display="flex" bg="transparent" borderRadius="100px" h="48px"
                    w="120px" border="1px solid" borderColor="gray.300"
                >
                    <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
                    <Text fontSize="md" mx="10px">Back</Text>
                </Button>

                <Heading fontSize="30px" mx="15px">Patient Status</Heading>
            </HStack>

            { data?.patients_by_pk &&
                <>
                    <Card boxShadow="null" borderRadius="16px" border="1px solid" borderColor="gray.100" position="relative"
                          backgroundImage={ellipse6} backgroundRepeat="no-repeat" backgroundPosition="top right" mt="20px"
                    >
                        <CardBody p="35px">
                            <HStack alignItems="center">
                                <Heading fontWeight="800" fontSize="32px">
                                    {data.patients_by_pk.first_name + ' ' + data.patients_by_pk.last_name}
                                </Heading>
                                <Text fontWeight="500" fontSize="20px"> - {data.patients_by_pk.customer_code}</Text>
                            </HStack>
                            <HStack mt="20px" flexWrap="wrap">
                                <Square borderRadius="8px" size="36px" bg="#FFF2F7" color="#FF0066">
                                    <Icon fontSize="20px" as={FaRegEnvelope}></Icon>
                                </Square>
                                <Text fontWeight="400" fontSize="15px">
                                    {data.patients_by_pk.email}
                                </Text>
                                <Box maxW="50px" flexGrow="1"></Box>
                                <Square borderRadius="8px" size="36px" bg="#F0F6FF" color="#0065FF">
                                    <Icon fontSize="20px" as={FiPhone}></Icon>
                                </Square>
                                <Text fontWeight="400" fontSize="15px">
                                    {data.patients_by_pk.mobile}
                                </Text>
                                <Box flexGrow="1"></Box>
                                <Button display="flex" bg="transparent" borderRadius="100px" h="37px"
                                    w="133px" border="1px solid" borderColor="gray.300"
                                    onClick={
                                        () => {setPatientInfoModalIsOpen(true)}
                                    }
                                >
                                    <Text fontSize="15px" fontWeight="400" mx="10px">View More</Text>
                                    <Icon as={FiArrowUpRight}></Icon>
                                </Button>
                            </HStack>
                        </CardBody>
                    </Card>

                    <Card boxShadow="null" borderRadius="16px" border="1px solid" borderColor="gray.100" position="relative"
                          mt="20px"
                    >
                        <CardBody p="35px">
                            <VStack h="full" borderStart="1px solid" borderColor="gray.300" px="30px" w="full">
                                <HStack w="full" position="relative">
                                    <Circle bg="blue.200" size="20px" marginStart="-40px">
                                        <Circle bg="white" border="4px solid" size="12px" borderColor="blue.500"></Circle>
                                    </Circle>
                                    <Heading fontSize="24px" ps="20px">Treatment Plan</Heading>
                                </HStack>

                                <Box w="full" pb="50px">

                                    { data?.patients_by_pk?.treatments && data?.patients_by_pk?.treatments?.length === 0 &&
                                        <Box mb="35px" w="full" textAlign="center">No treatment plan available</Box>
                                    }

                                    { data?.patients_by_pk?.treatments && data?.patients_by_pk?.treatments?.length > 0 &&

                                        <Card mb="15px" boxShadow="null" borderRadius="16px" border="1px solid"
                                              borderColor="gray.300" position="relative">
                                            <CardBody>
                                                <Heading fontSize="24px" ps="20px">Treatments</Heading>
                                                <SimpleGrid mt="15px" w="full" maxW="full" minChildWidth={{
                                                    base: "330px", md: "450px"
                                                }}>
                                                    { data.patients_by_pk.treatments.map((treatmentUser:any) => {
                                                        return (
                                                            <Card mx="15px" mb="30px" boxShadow="null" borderRadius="16px" border={treatmentUser.id === selectedTreatment?.id ? '3px solid' : '1px solid'}
                                                                borderColor={treatmentUser.id === selectedTreatment?.id ? 'blue.500' : 'gray.300'} position="relative" cursor="pointer"
                                                                key={treatmentUser.id}
                                                                onClick={() => {
                                                                    setSelectedTreatment(treatmentUser);
                                                                }}
                                                                bg={treatmentUser.status === 'CURRENT' ? 'white' : '#F0F0F0'}
                                                            >
                                                                <CardBody>
                                                                    <HStack spacing="5">
                                                                        <Image
                                                                            src={treatmentUser.treatment.image}
                                                                            alt={treatmentUser.treatment.title}
                                                                            w="184px"
                                                                            borderRadius='16px'
                                                                        />
                                                                        <VStack mt='5px' spacing='7' alignItems="start">
                                                                            <Heading fontSize="20px" fontWeight="800">{treatmentUser.treatment.title}</Heading>
                                                                            <Box textAlign="start">
                                                                                <Text color="gray.500" fontSize="16px" fontWeight="400" mb="5px">Sessions</Text>
                                                                                <Text fontWeight="500" fontSize="15px" >{treatmentUser.session_number} Required  {getNumberOfDoneSessions(treatmentUser?.sessions)} Done</Text>
                                                                            </Box>
                                                                            <HStack>
                                                                                <Box>
                                                                                    {treatmentUser.status === "DONE" &&
                                                                                        <HStack color="#138636" bg="#E7F3EB" fontSize="14px" fontWeight="500" px="10px" py="5px" borderRadius="8px">
                                                                                            <Text>Past</Text>
                                                                                            <CheckIcon fontSize="12px"></CheckIcon>
                                                                                        </HStack>
                                                                                    }
                                                                                    {treatmentUser.status === "CURRENT" &&
                                                                                        <HStack color="#0065FF" bg="#F0F6FF" fontSize="14px" fontWeight="500" px="10px" py="5px" borderRadius="8px">
                                                                                            <Text>Current</Text>
                                                                                            <Icon as={ImSpinner} fontSize="12px"></Icon>
                                                                                        </HStack>
                                                                                    }

                                                                                    {treatmentUser.status === "PENDING" &&
                                                                                        <HStack color="#138636" bg="#E7F3EB" fontSize="14px" fontWeight="500" px="10px" py="5px" borderRadius="8px">
                                                                                            <Text>Pending</Text>
                                                                                            <RepeatClockIcon fontSize="12px"></RepeatClockIcon>
                                                                                        </HStack>
                                                                                    }

                                                                                    {treatmentUser.status === "CANCELED" &&
                                                                                        <HStack color="#D94929" bg="#FFF3F0" fontSize="14px" fontWeight="500" px="10px" py="5px" borderRadius="8px">
                                                                                            <Text>Canceled</Text>
                                                                                            <SmallCloseIcon fontSize="12px"></SmallCloseIcon>
                                                                                        </HStack>
                                                                                    }
                                                                                </Box>
                                                                                <Circle size="32px" border="1px solid" borderColor="#B7B9C4">
                                                                                    <ArrowForwardIcon></ArrowForwardIcon>
                                                                                </Circle>
                                                                                <Menu>
                                                                                    <MenuButton w="32px" h="32px" minW="32px" borderRadius="full" border="1px solid" borderColor="#B7B9C4"
                                                                                        as={IconButton}
                                                                                        aria-label='Options'
                                                                                        icon={<HiOutlineDotsHorizontal />}
                                                                                        variant='outline'
                                                                                    />
                                                                                    <MenuList>
                                                                                        {treatmentUser.status !== 'DONE' &&
                                                                                            <MenuItem icon={<CheckIcon />}
                                                                                                onClick={() => {
                                                                                                    setCompleteTreatmentModalIsOpen(true);
                                                                                                }}
                                                                                            >
                                                                                                Set as complete
                                                                                            </MenuItem>
                                                                                        }

                                                                                        {treatmentUser.status !== 'CURRENT' &&
                                                                                            <MenuItem icon={<TimeIcon />}
                                                                                                onClick={() => {
                                                                                                    setCurrentTreatmentModalIsOpen(true);
                                                                                                }}
                                                                                            >
                                                                                                Set as current
                                                                                            </MenuItem>
                                                                                        }
                                                                                        {treatmentUser.status !== 'CANCELED' &&
                                                                                            <MenuItem icon={<SmallCloseIcon />}
                                                                                                onClick={() => {
                                                                                                    setCancelTreatmentModalIsOpen(true);
                                                                                                }}
                                                                                            >
                                                                                                Cancel Treatment
                                                                                            </MenuItem>
                                                                                        }
                                                                                        {/*<MenuItem icon={<DeleteIcon />}*/}
                                                                                        {/*    onClick={() => {*/}
                                                                                        {/*        setDeleteTreatmentModalIsOpen(true);*/}
                                                                                        {/*    }}*/}
                                                                                        {/*>*/}
                                                                                        {/*    Delete Treatment*/}
                                                                                        {/*</MenuItem>*/}
                                                                                    </MenuList>
                                                                                </Menu>
                                                                            </HStack>
                                                                        </VStack>
                                                                    </HStack>
                                                                </CardBody>
                                                            </Card>
                                                        )
                                                    })}
                                                </SimpleGrid>

                                                {selectedTreatment &&
                                                    <Box>
                                                        <Heading fontSize="24px" ps="20px">Sessions ({selectedTreatment.treatment.title})</Heading>
                                                        <SimpleGrid mx="auto" mt="15px" w="full" maxW="full" px="20px" minChildWidth={{
                                                            base: "330px", md: "235px"
                                                        }}>

                                                            { selectedTreatment?.sessions?.map((session:any, index:number) => {
                                                                return (
                                                                    <Card h="122" maxW="235px" border="1px solid" borderColor="#E6E6E6" key={session.id} mb="25px" boxShadow="none" cursor="pointer">
                                                                        <CardBody display="flex" justifyContent="center" alignItems="center">
                                                                            <HStack justifyContent="space-between" w="full" h="full">
                                                                                <VStack bg={session.status === 'DONE' ? '#61AE78' : '#F6F6F9'} color={session.status === 'DONE' ? 'white' : 'gray.800'} h="full" w="48px"  borderRadius="100px" justifyContent="center">
                                                                                    <Text fontSize="14px" fontWeight="500">
                                                                                        {session.date &&
                                                                                            <>{getDayName((new Date(session.date)).getDay())}</>
                                                                                        }

                                                                                        {!session.date &&
                                                                                            <>-</>
                                                                                        }
                                                                                    </Text>
                                                                                    <Text fontSize="14px" fontWeight="500">
                                                                                        {session.date &&
                                                                                            <>{(new Date(session.date)).getDate().toString().padStart(2, '0')}</>
                                                                                        }

                                                                                        {!session.date &&
                                                                                            <>-</>
                                                                                        }
                                                                                    </Text>
                                                                                </VStack>
                                                                                <VStack h="full" minW="48px" flex="1" justifyContent="center" alignItems="start" ms="10px">
                                                                                    <Heading fontSize="16px" fontWeight="700">Session {index + 1}</Heading>
                                                                                    <Text fontSize="14px" fontWeight="500">
                                                                                        {session.start_time &&
                                                                                            <>{session.start_time}</>
                                                                                        }
                                                                                        {(session.start_time || session.end_time) &&
                                                                                            <Text as="span"> - </Text>
                                                                                        }

                                                                                        {session.end_time &&
                                                                                            <>{session.end_time}</>
                                                                                        }
                                                                                        {!session.start_time && !session.end_time &&
                                                                                            <>--</>
                                                                                        }
                                                                                    </Text>
                                                                                </VStack>
                                                                                <Box w="35px">
                                                                                    <Circle as={ReactRouterLink} to={`/dashboard/patient/${params.id}/treatment/${selectedTreatment.id}/session/${session.id}`} size="32px" border="1px solid" borderColor="#B7B9C4">
                                                                                        <ArrowForwardIcon></ArrowForwardIcon>
                                                                                    </Circle>
                                                                                </Box>
                                                                            </HStack>
                                                                        </CardBody>
                                                                    </Card>
                                                                );
                                                            }) }
                                                            {selectedTreatment.status !== 'CANCELED' && selectedTreatment.status !== 'DONE' &&
                                                                <Card h="122" maxW="235px" border="0" boxShadow="none" background={borderBg} cursor="pointer"
                                                                    onClick={() => {
                                                                        setAddSessionModalIsOpen(true);
                                                                    }}
                                                                >
                                                                    <CardBody display="flex" justifyContent="center" alignItems="center">
                                                                        <Text fontWeight="500" fontSize="16px" color="#0065FF">New Session  +</Text>
                                                                    </CardBody>
                                                                </Card>
                                                            }
                                                        </SimpleGrid>
                                                    </Box>
                                                }


                                            </CardBody>
                                        </Card>

                                    }

                                    <Button w="full" h="48px" bg="#181D40" borderRadius="100px" color="white"
                                        onClick={() => {
                                            setAddTreatmentModalIsOpen(true);
                                        }}
                                    >Assign Treatment Plan +</Button>
                                </Box>

                                <HStack w="full" position="relative">
                                    <Circle bg="#c1efb9" size="20px" marginStart="-40px">
                                        <Circle bg="white" border="4px solid" size="12px" borderColor="#429E5E"></Circle>
                                    </Circle>
                                    <Heading fontSize="24px" ps="20px">Fitz Skin Type</Heading>
                                </HStack>

                                <Box w="full" pb="50px">
                                    <Card boxShadow="null" borderRadius="16px" border="1px solid"
                                          borderColor="gray.300" position="relative" mt="20px" w="full"
                                    >
                                        <CardBody>
                                            <HStack w="full">
                                                {!fitzSkinTypeFormData &&
                                                    <>
                                                        <Text flexGrow="1">Uncompleted</Text>
                                                        <Button display="flex" bg="transparent" borderRadius="100px" h="37px"
                                                                border="1px solid" borderColor="gray.300"
                                                                as={ReactRouterLink} to={`/dashboard/patient/${params.id}/skin/`}
                                                        >
                                                            <Text fontSize="15px" fontWeight="400" mx="10px">Complete Form</Text>
                                                            <Icon as={FiArrowRight}></Icon>
                                                        </Button>
                                                    </>
                                                }

                                                {fitzSkinTypeFormData &&
                                                    <>
                                                        <Text flexGrow="1">Completed
                                                            in {getHRFormat(new Date(fitzSkinTypeFormData.created_at))}</Text>
                                                        <Button display="flex" bg="transparent" borderRadius="100px" h="37px"
                                                                border="1px solid" borderColor="gray.300"
                                                                as={ReactRouterLink} to={`/dashboard/patient/${params.id}/skin/${fitzSkinTypeFormData.id}`}
                                                        >
                                                            <Text fontSize="15px" fontWeight="400" mx="10px">Edit Form</Text>
                                                            <Icon as={EditIcon}></Icon>
                                                        </Button>
                                                    </>
                                                }
                                            </HStack>
                                        </CardBody>
                                    </Card>
                                </Box>

                                <HStack w="full" position="relative">
                                    <Circle bg="red.200" size="20px" marginStart="-40px">
                                        <Circle bg="white" border="4px solid" size="12px" borderColor="red.500"></Circle>
                                    </Circle>
                                    <Heading fontSize="24px" ps="20px">Consultation Form</Heading>
                                </HStack>

                                <VStack w='full' spacing="30px">
                                    {consultationFormData &&
                                        <Card boxShadow="null" borderRadius="16px" border="1px solid"
                                              borderColor="gray.300" position="relative" mt="20px" w="full"
                                        >
                                            <CardBody>
                                                <HStack w="full">
                                                    <Text flexGrow="1">Sent
                                                        in {getHRFormat(new Date(consultationFormData.created_at))}</Text>
                                                    <Button display="flex" bg="transparent"
                                                            borderRadius="100px" h="37px"
                                                            w="133px" border="1px solid"
                                                            borderColor="gray.300"
                                                            as={ReactRouterLink}
                                                            to={`/dashboard/patient/${params.id}/form/${consultationFormData.id}`}
                                                    >
                                                        <Text fontSize="15px" fontWeight="400" mx="10px">View
                                                            More</Text>
                                                        <Icon as={FiArrowUpRight}></Icon>
                                                    </Button>

                                                </HStack>
                                            </CardBody>
                                        </Card>
                                    }
                                </VStack>
                            </VStack>
                        </CardBody>
                    </Card>

                    <ModalBox isOpen={patientInfoModalIsOpen} onClose={() => {setPatientInfoModalIsOpen(false)}}
                        size="2xl" icon={FaRegUser} iconsize="24px"
                        cancelButton={patientInfoModalCancelButton()}
                    >
                        <Box px="45px">
                            <Heading size="md" textAlign="center">Patient Information</Heading>
                            <HStack w="full" my="30px" borderBottom="1px dashed" borderColor="gray.300" pb="30px">
                                <VStack flexGrow="1" alignItems="start">
                                    <Text fontSize="13px" color="gray.500">Occupation</Text>
                                    <Text fontSize="14px">{data.patients_by_pk.occupation}</Text>
                                </VStack>
                                <VStack flexGrow="1" alignItems="start">
                                    <Text fontSize="13px" color="gray.500">Birthday</Text>
                                    <Text fontSize="14px">{data.patients_by_pk.birthday}</Text>
                                </VStack>
                            </HStack>
                            <HStack w="full" my="30px" borderBottom="1px dashed" borderColor="gray.300" pb="30px">
                                <VStack flexGrow="1" alignItems="start">
                                    <Text fontSize="13px" color="gray.500">Address</Text>
                                    <Text fontSize="14px">{data.patients_by_pk.address.full}</Text>
                                </VStack>
                                <VStack flexGrow="1" alignItems="start">
                                    <Text fontSize="13px" color="gray.500">Contact Method</Text>
                                    <Text fontSize="14px">{data.patients_by_pk.contact_method}</Text>
                                </VStack>
                            </HStack>
                            <HStack w="full" my="30px" borderBottom="1px dashed" borderColor="gray.300" pb="30px">
                                <VStack flexGrow="1" alignItems="start">
                                    <Text fontSize="13px" color="gray.500">Emergency Contact Full Name</Text>
                                    <Text fontSize="14px">{data.patients_by_pk.emergency_contact.emergency_contact_full_name}</Text>
                                </VStack>
                                <VStack flexGrow="1" alignItems="start">
                                    <Text fontSize="13px" color="gray.500">Emergency Contact Phone</Text>
                                    <Text fontSize="14px">{data.patients_by_pk.emergency_contact.emergency_contact_phone}</Text>
                                </VStack>
                            </HStack>
                            <HStack w="full" mt="30px">
                                <VStack flexGrow="1" alignItems="start">
                                    <Text fontSize="13px" color="gray.500">Emergency Contact email</Text>
                                    <Text fontSize="14px">{data.patients_by_pk.emergency_contact.emergency_contact_email}</Text>
                                </VStack>
                            </HStack>
                        </Box>
                    </ModalBox>

                    <AddTreatmentModal isOpen={addTreatmentModalIsOpen} patient={data?.patients_by_pk}
                        closeAction={async () => {
                        setAddTreatmentModalIsOpen(false);
                        await client.refetchQueries({
                            include: [GET_PATIENT_DATA],
                        });
                    }}></AddTreatmentModal>
                    
                    <Confirmation isOpen={cancelTreatmentModalIsOpen}
                        closeAction={() => {
                            setCancelTreatmentModalIsOpen(false)
                        }}
                        acceptAction={async () => {
                            let result = await updateTreatmentUserStatus({
                                variables: {
                                    id: selectedTreatment.id,
                                    status: 'CANCELED'
                                }
                            });

                            if (result?.data?.update_treatment_users_by_pk?.id) {
                                setSelectedTreatment(null);
                                await client.refetchQueries({
                                    include: [GET_PATIENT_DATA],
                                });
                            }
                            setCancelTreatmentModalIsOpen(false)
                        }}
                    >
                        Are you sure to cancel <Text as="span" fontWeight="700">{selectedTreatment?.treatment?.title}</Text>?
                    </Confirmation>

                    <Confirmation isOpen={deleteTreatmentModalIsOpen}
                        closeAction={() => {
                            setDeleteTreatmentModalIsOpen(false)
                        }}
                        acceptAction={() => {

                        }}
                    >
                        Are you sure to remove <Text as="span" fontWeight="700">{selectedTreatment?.treatment?.title}</Text>?
                    </Confirmation>

                    <Confirmation isOpen={completeTreatmentModalIsOpen}
                        closeAction={() => {
                            setCompleteTreatmentModalIsOpen(false)
                        }}
                        acceptAction={async () => {
                            let result = await updateTreatmentUserStatus({
                                variables: {
                                    id: selectedTreatment.id,
                                    status: 'DONE'
                                }
                            });

                            if (result?.data?.update_treatment_users_by_pk?.id) {
                                setSelectedTreatment(null);
                                await client.refetchQueries({
                                    include: [GET_PATIENT_DATA],
                                });
                            }

                            setCompleteTreatmentModalIsOpen(false)
                        }}
                    >
                        Are you sure to complete <Text as="span" fontWeight="700">{selectedTreatment?.treatment?.title}</Text>?
                    </Confirmation>

                    <Confirmation isOpen={currentTreatmentModalIsOpen}
                        closeAction={() => {
                            setCurrentTreatmentModalIsOpen(false)
                        }}
                        acceptAction={async () => {
                            let result = await updateTreatmentUserStatus({
                                variables: {
                                    id: selectedTreatment.id,
                                    status: 'CURRENT'
                                }
                            });

                            if (result?.data?.update_treatment_users_by_pk?.id) {
                                setSelectedTreatment(null);
                                await client.refetchQueries({
                                    include: [GET_PATIENT_DATA],
                                });
                            }

                            setCurrentTreatmentModalIsOpen(false)
                        }}
                    >
                        Are you sure to reOpen <Text as="span" fontWeight="700">{selectedTreatment?.treatment?.title}</Text>?
                    </Confirmation>

                    {addSessionModalIsOpen &&
                        <AddSessionModal isOpen={addSessionModalIsOpen}
                            closeAction={async () => {
                                setAddSessionModalIsOpen(false)
                            }}
                            saveAction={async(id:number) => {
                                setSelectedTreatment(null);
                                // CREATE FOLDERS
                                let basePath = getPatientImageBasePath(data?.patients_by_pk, selectedTreatment?.treatment?.descriptor, selectedTreatment.id);
                                let index = selectedTreatment.sessions.length + 1;
                                let folders = [
                                    basePath + 'session_' + index + '_' + id + '/',
                                    basePath + 'session_' + index + '_' + id + '/after/',
                                    basePath + 'session_' + index + '_' + id + '/before/',
                                ];

                                let createFolderResult = await axiosInstance().post('/storage/create_folder', {
                                    keys: folders
                                });

                                await client.refetchQueries({
                                    include: [GET_PATIENT_DATA],
                                });
                            }}
                            defaultState={selectedSessionData}
                        ></AddSessionModal>
                    }
                </>
            }

        </Container>
    );
}


export default Patient;