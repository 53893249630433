import { gql } from "@apollo/client";

export const GET_CONSULTATION_FORM_STRUCTURE = gql`
    query {
        form_schemas(where: {
                descriptor: {_eq: "CONSULTATION_FORM"},
                center_id: {_eq: ${process.env.REACT_APP_CENTER_ID}}
        }) {
            id,
            version,
            template
        }
    }
`;
//

export const ADD_PATIENT_DATA = gql`
    mutation InsertPatient($address: jsonb! $birthday: String! $customer_code: String! $email: String! $email_verified_at: String! $emergency_contact: jsonb! $first_name: String! $last_name: String! $mobile: String! $mobile_verified_at: String! $occupation: String! $contact_method: String!) {
        insert_patients_one(object: {address: $address, birthday: $birthday, center_id: ${process.env.REACT_APP_CENTER_ID}, customer_code: $customer_code, email: $email, email_verified_at: $email_verified_at, emergency_contact: $emergency_contact, first_name: $first_name, last_name: $last_name, mobile: $mobile, mobile_verified_at: $mobile_verified_at, occupation: $occupation, contact_method: $contact_method}) {
            id
        }
    }
`;

export const UPDATE_PATIENT_DATA = gql`
    mutation UpdatePatient($id: Int! $address: jsonb! $customer_code: String! $birthday: String! $email: String! $emergency_contact: jsonb! $first_name: String! $last_name: String! $mobile: String! $occupation: String! $contact_method: String!) {
        update_patients_by_pk(pk_columns: {id: $id}, _set: {address: $address, customer_code: $customer_code, birthday: $birthday, email: $email, emergency_contact: $emergency_contact, first_name: $first_name, last_name: $last_name, mobile: $mobile, occupation: $occupation, contact_method: $contact_method}) {
            id
        }
    }
`;

export const ADD_CONSULTATION_DATA = gql`
    mutation InsertFormData($data: jsonb! $patient_id: Int!) {
        insert_form_data_one(object: {center_id:  ${process.env.REACT_APP_CENTER_ID}, data: $data, form_id: 1, patient_id: $patient_id, version: 1}) {
            id
        }
    }
`;


