import {gql} from "@apollo/client";

export const GET_USER_DATA = gql`
    query getUserData($id: Int!) {
        users_by_pk(id: $id) {
            email
            id
            mobile
            name
        }
    }
`;
