import React, {ReactNode, useEffect, useState} from 'react';
import {
    Box,
    Flex,
    Text,
    Circle,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    Progress,
    StepTitle,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Link, useDisclosure, Heading, Stack, ModalProps, Icon, HStack
} from "@chakra-ui/react";
import {FormItem, FormItemProps} from "../../interfaces/Interfaces"
import {CheckCircleIcon, PhoneIcon} from "@chakra-ui/icons";
import TextBox from "../FromsElements/TextBox";

interface ModalBoxState {
    mobile: string,
    verified: boolean,
    inProgress: boolean
}

interface ModalBoxProps extends ModalProps {
    data?: ModalBoxState,
    onContinue?: any,
    icon?: any,
    iconsize?: any,
    actionButton?: any,
    cancelButton?: any
}

const ModalBox = (props: ModalBoxProps) => {
    const {isOpen, onClose, onContinue} = props;
    const actionButton:ReactNode = props.actionButton ?? null;
    const cancelButton:ReactNode = props.cancelButton ?? null;
    const icon = props.icon ?? null;
    const iconSize = props.iconsize ?? null;

    return (
        <Modal
            {...props}
            isCentered
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>
                    {icon &&
                        <Stack w="full" h="60px" mt="25px" justifyContent="center" alignItems="center">
                            <Circle size="56px" bg="#F0F6FF" color="#0065FF">
                                <Icon as={icon} fontSize={iconSize ? iconSize : 'xl'}></Icon>
                            </Circle>
                        </Stack>
                    }
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    {props.children}
                </ModalBody>

                <ModalFooter>
                    <HStack w="full" align='center' spacing="5">

                        {cancelButton !== null &&
                            cancelButton
                        }

                        {actionButton !== null &&
                            actionButton
                        }

                    </HStack>

                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalBox;