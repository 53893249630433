import ModalBox from "../../../../../components/ModalBox";
import {FiFilePlus} from "react-icons/fi";
import {Box, Button, Heading, Text, Alert, AlertIcon, VStack, Circle, Flex, HStack} from "@chakra-ui/react";
import SelectBox from "../../../../../components/FromsElements/SelectBox";
import * as React from "react";
import {useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    ADD_TREATMENT_USER,
    ADD_TREATMENT_USER_SESSION_NOTES,
    ADD_TREATMENT_USER_SESSIONS,
    GET_TREATMENTS, UPDATE_TREATMENT_USER_SESSION_NOTE, UPDATE_TREATMENT_USER_SESSION_PRESCRIPTION
} from "../../queries";
import useAuth from "../../../../../hooks/useAuth";
import {useParams} from "react-router-dom";
import useCustomToast from "../../../../../hooks/useCustomToast";
import TextBox from "../../../../../components/FromsElements/TextBox";
import DatePickerBox from "../../../../../components/FromsElements/DatePickerBox";
import TextAreaBox from "../../../../../components/FromsElements/TextAreaBox";
import {FormItem} from "../../../../../interfaces/Interfaces";
interface AddNoteModalProps {
    isOpen: boolean,
    closeAction: any,
    addAction: any,
    defaultState: {
        id: number|null,
        text: String|null
    }
}
const AddNoteModal = (props:AddNoteModalProps) => {
    const {user_id} = useAuth();
    let params = useParams();
    const [addSessionModalIsOpen, setAddNoteModalIsOpen] = useState(false);
    const [addTreatmentUserSessionNotes] = useMutation(ADD_TREATMENT_USER_SESSION_NOTES)
    const [updateTreatmentUserSessionNoteByPk] = useMutation(UPDATE_TREATMENT_USER_SESSION_NOTE)

    const toast = useCustomToast();
    const [stateData, setStateData] = useState<any>(props.defaultState);
    const [loading, setLoading] = useState(false);

    const addNoteModalCancelButton = () => {

        return (
            <Button variant="btnNoraml" border="1px solid" borderColor="gray.400" w="full"
                    borderRadius="100px" h="48px" mb="30px" ms="45px"
                    onClick={() => {
                        setStateData({text: '', id: null});

                        props.closeAction()
                    }}
            >Cancel</Button>
        );
    }

    const addNoteModalActionButton = () => {

        return (
            <Button variant="btnMain" border="1px solid" borderColor="gray.400" w="full"
                borderRadius="100px" h="48px" mb="30px" me="45px" isDisabled={loading || !stateData?.text?.length}
                onClick={async () => {
                    setLoading(true);
                    let hasError = false;
                    if (stateData.id) {
                        let result = await updateTreatmentUserSessionNoteByPk({
                            variables: {
                                id: stateData.id,
                                object:                                     {
                                    text: stateData.text,
                                }
                            }
                        });
                        hasError = !result?.data?.update_treatment_user_session_notes_by_pk?.id;
                    } else {
                        let result = await addTreatmentUserSessionNotes({
                            variables: {
                                objects: [
                                    {
                                        patient_id: params.pid,
                                        text: stateData.text,
                                        treatment_user_id: params.tid,
                                        treatment_user_session_id: params.sid,
                                        user_id: user_id
                                    }
                                ]
                            }
                        });

                        hasError = !result?.data?.insert_treatment_user_session_notes?.affected_rows;
                    }

                    setLoading(false);
                    if (!hasError) {
                        toast.success('Note has been successfully saved.');
                        setStateData({text: '', id: null});
                        props.addAction();
                    }
                }}
            >Save</Button>
        );
    }

    return (
        <ModalBox isOpen={props.isOpen} onClose={() => {setStateData({text: '', id: null}); props.closeAction()}}
            size="xl" icon={FiFilePlus} iconsize="26px"
            cancelButton={addNoteModalCancelButton()}
            actionButton={addNoteModalActionButton()}
        >
            <Box>
                <Heading size="md" textAlign="center">Add new Note</Heading>

                <TextAreaBox state={stateData}
                    item={{
                        descriptor: 'text',
                        title: "Note",
                        values: {},
                        options: {
                            props: {
                                h: "250px"
                            }
                        }
                    }}
                    inputItemHandler={(value:string, item:FormItem) => {
                        setStateData({...stateData, text: value});
                    }}
                ></TextAreaBox>
            </Box>
        </ModalBox>
    );
}

export default AddNoteModal;